import React, { useState, useEffect } from 'react';
import SvModal from "./SvModal";
import axios from "axios";

function Sv() {

    const newData = {
        "name": "",
        "tell": "",
        "facName": "",
        "owner": "",
        "accName": "",
        "accNum": "",
        "accBK": "",
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [svInfo, setSvInfo] = useState([]);
    const [svDetail, setSvDetail] = useState({});
    const [mode, setMode] = useState();
    const [keyword, setKeyword] = useState('');
    const [originList, setOriginList] = useState([]);



    useEffect(() => { getSvList(); }, []);
    useEffect(() => { getSvList(); }, [isModalOpen]);

    const getSvList = async () => {
        await axios.get('/api/admin/sv')
            .then(result => {
                setSvInfo(result.data);
                setOriginList(result.data);
            })
            .catch(error => {

            });
    };

    const kw = (event) => {
        setKeyword(event.target.value);
    }
    const search = async () => {
        const cate = document.querySelector('.searchCate').value;

        const result = originList.filter(order => order[cate].includes(keyword));
        setSvInfo(result);

    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openDetail = async (svId) => {
        setMode('view');
        setSvDetail(svId);
        openModal()
    }

    const openRegModal = () => {
        setMode('new');
        setSvDetail({...newData});
        openModal();
    };

    const updateIsUse = async (event, sv) => {
        const update = { _id: sv._id, isUse: !sv.isUse };
        await axios.patch(`/api/admin/sv/${sv._id}`, update).then(
            result => {
                getSvList();
            }
        ).catch(err => {
            alert('잠시 후 다시 시도해 주세요')
        });

    };


    return (
        <div>
            {/* 컨트롤영역 */}
            <div className={'row d-flex justify-content-center my-3'}>
                <div className={'col-4 d-flex justify-content-start px-0'}>
                    <button className={'btn btn-sm btn-dark rounded-4 mx-3'} onClick={ openRegModal }>수퍼바이저등록</button>
                </div>
                <div className={'col-6 d-flex px-0 justify-content-end'}>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-2 border-3 rounded-4'}  onClick={getSvList}>전체</button>
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center px-0'}>*/}
                    <select className={'form-select-sm cate border-3 border-black rounded-4 searchCate'} defaultValue={'name'}>
                        <option value={'name'}>이름</option>
                        <option value={'memId'}>아이디</option>
                    </select>
                    {/*</div>*/}
                    {/*<div className={'col-2 d-flex justify-content-center'}>*/}
                    <input style={{width : "200px"}} type={'text'}
                           placeholder={'검색어를 입력하세요'}
                           className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           onChange={ kw }
                    />
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center'}>*/}
                    <button className={'btn btn-sm btn-dark rounded-4'} onClick={ search }>검색</button>
                </div>
            </div>


            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>NO</td>
                            <td>이름</td>
                            <td>회원ID</td>
                            <td>연락처</td>
                            {/*<td>사용여부</td>*/}
                            <td>상세정보</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            svInfo.map((sv, idx) => (
                                <tr key={sv._id}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <span>
                                            {sv.name}
                                        </span>
                                    </td>
                                    <td>{sv.memId}</td>
                                    <td>{sv.tell}</td>
                                    {/*<td>*/}
                                    {/*    <div className="form-check form-switch justify-content-center align-items-center d-flex">*/}
                                    {/*        <input className="form-check-input d-inline-flex focus-ring focus-ring-dark"*/}
                                    {/*               type="checkbox"*/}
                                    {/*               role="switch"*/}
                                    {/*               checked={sv.isUse}*/}
                                    {/*               onChange={ (event) => { updateIsUse(event, sv) }}*/}

                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td>
                                        <button className={'btn btn-sm btn-dark rounded-4'} onClick={ () => { openDetail(sv) }}>상세</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            
            
            <SvModal isOpen={isModalOpen} onClose={closeModal} mode={mode} svDetail={svDetail}></SvModal>

        </div>
    );
}

export default Sv;