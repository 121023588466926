import React, {useEffect, useState} from "react";
import ShopModal from "../shop/ShopModal";
import axios from "axios";

function Shop() {

    const newData = {
        "name": "",
        "address1": "",
        "address2": "",
        "tell": "",
        "owner": "",
        "accName": "",
        "accNum": "",
        "accBK": "",
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [shopInfo, setShopInfo] = useState([]);
    const [mode, setMode] = useState();
    const [shopDetail, setShopDetail] = useState({});
    const [keyword, setKeyword] = useState('');
    const [originShopList, setOriginShopList] = useState([]);


    useEffect( () => { getShopList(); }, []);
    useEffect( () => { getShopList(); }, [isModalOpen]);

    const getShopList = async () => {
        await axios.get('/api/admin/Shop')
            .then(result => {
                setShopInfo([...result.data]);
                setOriginShopList([...result.data]);
            })
            .catch(error => {

            });
    };

    const kw = (event) => {
        setKeyword(event.target.value);
    }

    const search = () => {
        const cate = document.querySelector('.searchCate').value;
        const result = originShopList.filter(order => order[cate].includes(keyword));
        setShopInfo(result);
    }
    
    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openDetail = async (shopId) => {
        setMode('view');
        setShopDetail(shopId);
        openModal()
    }

    const openRegModal = () => {
        setMode('new');
        setShopDetail({...newData});
        openModal();
    };

    const updateIsUse = async (event, shop) => {
        const update = { _id: shop._id, isUse: !shop.isUse };
        await axios.patch(`/api/admin/shop/${shop._id}`, update).then(
            result => {
                getShopList();
            }
        ).catch();

    };

    return (
        <div className="mx-2">
            {/* 컨트롤영역 */}
            <div className={'row d-flex justify-content-center my-3'}>
                <div className={'col-4 d-flex justify-content-start px-0'}>
                    <button className={'btn btn-sm btn-dark rounded-4'} onClick={ openRegModal }>상점등록</button>
                </div>
                <div className={'col-6 d-flex px-0 justify-content-end'}>
                    <select className={'form-select-sm cate border-3 border-black rounded-4 searchCate'}  defaultValue={'name'}>
                        <option value={'name'}>상점명</option>
                        <option value={'owner'}>점장명</option>
                        <option value={'memId'}>ID</option>
                    </select>
                    <input type={'text'}
                           style={{width : "200px"}}
                           placeholder={'검색어를 입력하세요'}
                           className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           value={keyword}
                           onChange={kw}
                    />
                    <button onClick={search} className={'btn btn-sm btn-dark rounded-4'} >검색</button>
                    <button onClick={getShopList} className={'btn btn-sm btn-dark rounded-4 mx-2'} >전체보기</button>
                </div>
            </div>
            {/*상품목록영역*/}
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>NO</td>
                            <td>상점명</td>
                            <td>점장명</td>
                            <td>회원ID</td>
                            <td>공방</td>
                            {/*<td>사용여부</td>*/}
                            <td>상세정보</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            shopInfo.map((shop, idx) => (
                                <tr key={shop._id}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <span>
                                            {shop.name}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {shop.owner}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {shop.memId}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {shop.facName}
                                        </span>
                                    </td>
                                    {/*<td>*/}
                                    {/*    <div className="form-check form-switch justify-content-center align-items-center d-flex">*/}
                                    {/*        <input className="form-check-input d-inline-flex focus-ring focus-ring-dark"*/}
                                    {/*               type="checkbox"*/}
                                    {/*               role="switch"*/}
                                    {/*               checked={shop.isUse}*/}
                                    {/*               onChange={ (event) => { updateIsUse(event, shop) }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td>
                                        <span>
                                            <button className={'btn btn-sm btn-dark rounded-4'} onClick={ () => { openDetail(shop) }}>상세</button>
                                        </span>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <ShopModal isOpen={isModalOpen} onClose={closeModal} mode={mode} shopDetail={shopDetail}></ShopModal>

        </div>
    );
}

export default Shop;
