import React, {useEffect, useState} from "react";
import {Routes, Route, useNavigate, Navigate} from 'react-router-dom'
import Admin from './page/admin/Admin.js'
import Item from './page/admin/item/Item.js'
import AdminShop from './page/admin/shop/Shop.js'
import Join from './page/join.js'
import Login from './page/login.js'
import Member from "./page/admin/Member";
import AdminFactory from "./page/admin/factory/Factory";
import User from "./page/user/User"
import Shop from "./page/shop/Shop"
import Factory from "./page/factory/Factory"
import Sv from "./page/admin/sv/Sv";
import UserItem from "./page/user/UserItem"
import MyInfo from "./page/user/MyInfo"
import OrderList from "./page/user/OrderList"
import AuthRedirect from "./page/AuthRedirect";
import PrivateRoute from './page/PrivateRoute';
import ShopOrderList from './page/shop/OrderList';
import ShopMember from "./page/shop/ShopMember.js";
import ShopInfo from "./page/shop/ShopInfo.js";
import FatoryShopList from "./page/factory/FactoryShopList.js";
import FactoryOrderList from "./page/factory/FactoryOrderList.js";
import FatoryInfo from "./page/factory/FactoryInfo.js";
import AdminOrder from "./page/admin/order/AdminOrder";
import UserHome from "./page/user/UserHome";
import Agree from "./page/Agree";
import './App.css';
import "./firebase";
import {getToken ,messaging} from "./firebase";
import { useRecoilState } from 'recoil';
import { userState, notificationTokenState } from './page/recoilState';
import axios from "axios";
import Download from "./page/Download";


function App() {

    const [token, setToken] = useRecoilState(notificationTokenState);
    const [user, setUser] = useRecoilState(userState);
    const [isPWA, setIsPWA] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        // PWA 지원 여부를 확인
        const isPWASupported = window.matchMedia('(display-mode: standalone)').matches;
        if (isPWASupported) {
            setIsPWA(true);
        }
    }, []);

    useEffect( () => {
        if(user){
            getToken(messaging).then(async (res) => {
                const fbToken = {
                    'fbToken': res
                }
                await axios.patch(`/api/member/${user._id}`, fbToken).then()
                setToken(res);
            });
        }
    }, [user]);

    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/service-worker.js')
                .then((registration) => {
                    // console.log('Service Worker registered with scope:', registration.scope);
                    // 서비스 워커가 설치되면 강제로 활성화
                    if (registration.waiting) {
                        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                    }
                })
                .catch((error) => {
                    // console.error('Service Worker registration failed:', error);
                });
        });
    }

    return (
        <Routes>
            <Route path="/" element={<AuthRedirect />} />
            <Route path="/login" element={<Login setIsPWA={setIsPWA}/>} />
            <Route path="/join" element={<Join />} />
            <Route path="/agree" element={<Agree />} />
            <Route path="/download" element={<Download setIsPWA={setIsPWA}/> } />
            <Route
                path="/admin/*"
                element={
                    <PrivateRoute roles={['admin']}>
                        <Admin />
                    </PrivateRoute>
                }
            >
                <Route index element={<Admin />} />
                <Route path={'order'} element={<AdminOrder />} />
                <Route path={'item'} element={<Item />} />
                <Route path={'shop'} element={<AdminShop />} />
                <Route path={'member'} element={<Member />} />
                <Route path={'factory'} element={<AdminFactory />} />
                <Route path={'sv'} element={<Sv />} />
            </Route>
            <Route
                path="/user/*"
                element={
                    <PrivateRoute roles={['user']}>
                        <User />
                    </PrivateRoute>
                }
            >
                <Route index element={<User />} />
                <Route path={'item'} element={<UserItem />} />
                <Route path={'userHome'} element={<UserHome />} />
                <Route path={'orderList'} element={<OrderList />} />
                <Route path={'myInfo'} element={<MyInfo />} />
            </Route>
            <Route
                path="/shop/*"
                element={
                    <PrivateRoute roles={['shop']}>
                        <Shop />
                    </PrivateRoute>
                }
            >
                <Route index element={<Shop />} />
                <Route path={'orderList'} element={<ShopOrderList />} />
                <Route path={'shopMember'} element={<ShopMember />} />
                <Route path={'shopInfo'} element={<ShopInfo />} />
            </Route>

            <Route
                path="/factory/*"
                element={
                    <PrivateRoute roles={['factory']}>
                        <Factory />
                    </PrivateRoute>
                }
            >
                <Route index element={<Factory />} />
                <Route path={'orderList'} element={<FactoryOrderList />} />
                <Route path={'factoryInfo'} element={<FatoryInfo />} />
                <Route path={'factoryShopList'} element={<FatoryShopList />} />
            </Route>

            <Route path="/access-denied" element={<Login />} />
        </Routes>
    );
}

export default App;
