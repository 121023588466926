import React, {useEffect, useState} from "react";
import { Outlet } from "react-router-dom";
import FactoryHeader from './FactoryHeader';
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";
import axios from "axios";

function Factory() {

    const [user, setUser] = useRecoilState(userState);
    const [myFact, setMyFact] = useState({});

    useEffect(() => { getMyFact() }, []);

    const getMyFact = async () => {
        await axios.get(`/api/fac/${user._id}`).then(reuslt => {
            setMyFact(reuslt.data);
        })
    }

    const contextValue = {
        myFact,
        getMyFact
    };

    return (
        <div className="container-fluid">
            <FactoryHeader />
            <Outlet context={ contextValue }></Outlet>
        </div>
    );
}

export default Factory;