import React, {useEffect, useState} from "react";
import ShopHeader from "./ShopHeader";
import { Routes, Route, Outlet } from "react-router-dom";
import axios from "axios";
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";

function Shop() {

    const [user, setUser] = useRecoilState(userState);
    const [myShop, setMyShop] = useState({});

    useEffect(() => { getMyShop() }, []);

    const getMyShop = async () => {
        await axios.get(`/api/shop/${user._id}`).then(reuslt => {
            setMyShop(reuslt.data);
        })
    }

    const contextValue = {
        myShop,
        getMyShop
    };

    return (
        <div className={'container-fluid mx-0'}>
            <ShopHeader />
            <Outlet context={ contextValue }></Outlet>
        </div>
    );
}

export default Shop;