import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function AdminHeader() {

    const logout = () => {
        axios.get('/api/member/logout').then(() => {
            navigate("/login");
        })
    }

    const navigate = useNavigate();
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">담배피는 호랑이 (관리자 페이지)</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <Link to={'/admin/order'} className="nav-link">주문조회</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/admin/item'} className="nav-link">상품관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/admin/shop'} className="nav-link">상점관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/admin/factory'} className="nav-link">공방관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/admin/member'} className="nav-link">회원관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/admin/sv'} className="nav-link">수퍼바이저관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a style={{cursor: "pointer"}} className="nav-link" onClick={logout}>로그아웃</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default AdminHeader;