import React, {useEffect, useState} from 'react';
import axios from 'axios';
import DaumPostcode from 'react-daum-postcode';
import button from "bootstrap/js/src/button";

function ShopModal({ isOpen, onClose, mode, shopDetail }) {

    const [shopInfo, setShopInfo] = useState({});
    const [openPostcode, setOpenPostcode] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(mode === 'new' ? true : mode === 'edit' ? true : false);
    const [loading, setLoading] = useState(true);
    const [memberList, setMemberList] = useState([]);

    const getMember = async () => {

        const query = 'memRole=shop';
        await axios.get('/api/member/role?'+query).then(result => {
            setMemberList([...result.data]);
        })
    }

    const addMember = () => {
        const selectedMemId = document.querySelector('.selectMember').value;
        const selectedMember = memberList.find(member => member._id === selectedMemId);

        if (selectedMemId) {
            setShopInfo((prevFactory) => {
                return {
                    ...prevFactory,
                    memCode: selectedMember._id,
                    memId: selectedMember.memId,
                };
            });
        }
    }

    useEffect(() => {
        setShopInfo(shopDetail);

        getMember();

        if(mode === 'new'){
            setIsNew(true);
            setIsEdit(true);
        }
        else {
            setIsNew(false);
            setIsEdit(false);
        }

        if (shopInfo) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [shopDetail]);


    const clickButton = () => {
        setOpenPostcode(current => !current);
    };

    const  selectAddress = (data) => {
        setShopInfo((prevFactory) => ({
            ...prevFactory,
            'address1': data.address, // name 속성을 키로 사용하여 값을 업데이트
        }));
        setOpenPostcode(false);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        // 현재 상태 복사본을 만들고 업데이트
        setShopInfo((prevFactory) => ({
            ...prevFactory,
            [name]: value, // name 속성을 키로 사용하여 값을 업데이트
        }));
    };

    const submit = async () => {
        await axios.post('/api/admin/shop', shopInfo).then(() => {
            alert('등록완료');
            setShopInfo({});
            onClose();
        }
        ).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;
    }

    const update = async () => {
        await axios.patch(`/api/admin/shop/${shopInfo._id}`, shopInfo).then((result) => {

                setShopInfo({});
                setIsEdit(false);
                onClose();
            }
        ).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;
    }

    const setEdit = () => {
        setIsEdit(!isEdit);
    }


    if (!isOpen) return null;

    return (
        loading ? <span>로딩중</span> :
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background">
                <div className="item-modal">
                    {isNew ?
                    <h3 className={'text-center'}>상점등록</h3>
                    : <h3 className={'text-center'}>상점상세정보</h3>
                    }
                    <div className={'row my-5 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">상점명</label>
                                {isEdit ?
                                    <>
                                        <input type="text"
                                               className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                               name={'name'}
                                               value={shopInfo.name}
                                               onChange={handleInputChange}
                                        />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.name}</h5>
                                    </>
                                }

                            </div>
                            <div className="col-5">
                            <label className="form-label mb-0">점주명</label>
                            {isEdit ?
                                <>
                                    <input type="text"
                                           className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                           name={'owner'}
                                           value={shopInfo.owner}
                                           onChange={handleInputChange}
                                    />

                                </>
                                :
                                <>
                                    <h5>{shopInfo.owner}</h5>
                                </>
                            }
                        </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">연락처</label>
                                {isEdit ?
                                    <>
                                    <input type="text"
                                           className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                           id=""
                                           name={'tell'}
                                           value={shopInfo.tell}
                                           onChange={handleInputChange}
                                    />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.tell}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8">
                                <label className="form-label mb-0">주소</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'address1'}
                                       value={shopInfo.address1}
                                       onChange={handleInputChange}
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.address1}</h5>
                                    </>
                                }
                            </div>
                            <div className={'col-2 d-flex pt-4'}>
                                <button className={'btn btn-sm btn-dark rounded-3'} onClick={clickButton}>검색</button>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-10">
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'address2'}
                                       value={shopInfo.address2}
                                       onChange={handleInputChange}
                                       placeholder={'상세주소입력'}
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.address2}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">은행</label>

                                {isEdit ?
                                    <>

                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accBK'}
                                       value={shopInfo.accBK}
                                       onChange={handleInputChange}
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.accBK}</h5>
                                    </>
                                }
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0">예금주</label>

                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accName'}
                                       value={shopInfo.accName}
                                       onChange={handleInputChange}
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.accName}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">계좌번호</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accNum'}
                                       value={shopInfo.accNum}
                                       onChange={handleInputChange}
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.accNum}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">상점ID</label>
                                {isEdit ?
                                    <>
                                        <select className={'form-select selectMember'} name={'memId'} onChange={addMember}>
                                            <option>선택</option>
                                            {memberList.map((member) => (
                                                <option value={member._id}>{member.memId}</option>
                                            ))}
                                        </select>
                                    </>
                                    :
                                    <>
                                        <h5>{shopInfo.memId}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex mt-3 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                                {isNew ?
                                <input type={'button'} onClick={submit} value={'등록'} className={'btn btn-dark rounded-3'}/>
                                    :
                                    isEdit ?
                                        <button className={'btn btn-dark rounded-3'} onClick={update}>저장</button>
                                        :
                                        <button className={'btn btn-dark rounded-3'} onClick={setEdit}>수정</button>
                                }
                                <input onClick={ () => { onClose(); setIsEdit(false); }} type={'button'} className={'btn btn-dark rounded-3 mx-3'} value={'닫기'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {openPostcode &&
                <div className={'item-modal-overlay'}>
                    <div className={'item-modal-background'}>
                        <div className={'item-modal'}>
                            <button type="button" className="btn-close shadow-none" onClick={clickButton} aria-label="Close"></button>
                            <DaumPostcode
                                onComplete={selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                            />
                        </div>
                    </div>
                </div>
            }
        </div>



    );

}

export default ShopModal;