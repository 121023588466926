import React, {useEffect, useRef, useState} from "react";
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";
import axios from "axios";
import OrderModal from "../admin/order/OrderModal";
import {writeFile, utils} from 'xlsx';


function Factory() {

    const [user, setUser] = useRecoilState(userState);
    const [myFac, setMyFac] = useState({});

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState({'startDate': new Date(0).toISOString(), 'endDate': new Date().toISOString()});
    const selectedCate = useRef();
    const [total, setTotal] = useState(1);

    const [orderList, setOrderList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [keyword, setKeyword] = useState('');
    const [selectedValue, setSelectedValue] = useState('전체');


    useEffect(() => { getMyFac() }, []);

    useEffect(() => {
        getMyFac()
    }, [page])

    useEffect(() => {
        getMyFac()

    }, [isModalOpen])

    useEffect(() => {
        getMyFac()
    }, [keyword])

    useEffect(() => {
        getMyFac()
    }, [searchKeyword]);

    const getExcelData = () => {
        axios.get(`/api/order/fac/${myFac._id}`).then((result) => {
            download(result.data);
        })
    }

    const getSearchExcel = () => {

        const cate = selectedCate.current.value;
        const payload = searchKeyword;
        payload[cate] = keyword;
        payload.factoryId = myFac._id;

        axios.post('/api/order/searchExcel', payload).then(result => {
            download(result.data)
        })
    }

    const download = (orderList) => {

        let result = [];
        orderList.forEach((order) => {
            const data = {
                '회원ID': order.memId,
                '회원명': order.memCode.memName,
                '연락처': order.memCode.memTell,
                '공방금액': order.factoryTotalPrice,
                '주문상점': order.storeName,
                '주문상태': order.orderState,
                '주문일시': order.orderDate,
            }
            result.push(data);
        })

        const ws = utils.json_to_sheet(result);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, "주문목록.xlsx");
    }

    const resetKeyword = () => {
        setKeyword('');
        const keysToKeep = ['startDate', 'endDate']; // 유지하려는 키 목록
        const updatedKeyword = Object.keys(searchKeyword)
            .filter(key => keysToKeep.includes(key)) // 유지해야 할 키만 필터링
            .reduce((obj, key) => {
                obj[key] = searchKeyword[key]; // 유지해야 할 키만 새 객체에 복사
                return obj;
            }, {
                'startDate': new Date(0).toISOString(),
                'endDate': new Date().toISOString(),
            });

        setSearchKeyword(updatedKeyword);
    }

    const reset = () => {
        resetKeyword();
        document.querySelector('.orderState').value = '전체'
        document.querySelector('.startDate').value = null
        document.querySelector('.endDate').value = null
        setPage(1);
    }

    const kw = (event) => {
        setKeyword(event.target.value);
    }

    const openModal = (order) => {
        setOrderDetail(order)
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        getMyFac();
    };

    const pageHandler = (number) => {
        setPage(number);
    }

    const searchKeywordHandler = (event) => {

        const cate = selectedCate.current.value;

        if (event) {
            if(event.target.value == '전체'){
                reset()
                return
            }

            setSearchKeyword(prev => ({
                ...prev,
                'page': page,
                [event.target.name]: event.target.value,
                [cate]: keyword,
            }));
        }
        else {
            setSearchKeyword(prev => ({
                ...prev,
                'page': page,
                [cate]: keyword,
            }));

        }
    }

    const orderState = (state, id) => {
        const data = {'orderState' : state };
        axios.patch(`/api/order/${id}`, data).then((result) => {
            getMyFac();
        })
    }

    const getMyFac = async () => {
        await axios.get(`/api/fac/${user._id}`).then(reuslt => {
            setMyFac(reuslt.data);
            getOrderList(reuslt.data._id);
        })
    }
    const getOrderList = async (_id) => {
        const cate = selectedCate.current.value;

        const payload = searchKeyword;
        payload[cate] = keyword;
        payload.page = page;
        payload.factoryId = _id;

        await axios.post('/api/order/searchOrder', payload).then(result => {
            setOrderList(result.data.result);
            setTotalPage(result.data.totalPage);
            setTotal(result.data.total);
        }).catch(e => {
            setOrderList([]);
            setTotalPage(1);
        })
    }

    return (
        <div className="">
            <div className={'row d-flex justify-content-center my-2'}>
                <div className={'col-1 px-0 d-flex justify-content-center'}>
                    <button className={'btn btn-dark rounded-4'} onClick={reset}>초기화</button>
                </div>
                <div className={'col-1 px-2'}>
                    <select className={'form-select orderState'}
                            name={'orderState'}
                            onChange={(event) => {
                                searchKeywordHandler(event);
                            }}>
                        <option value={'전체'}>전체</option>
                        <option value={'입금대기'}>입금대기</option>
                        <option value={'입금완료'}>입금완료</option>
                        <option value={'공방발송'}>공방발송</option>
                        <option value={'제작중'}>제작중</option>
                        <option value={'제작완료'}>제작완료</option>
                        <option value={'픽업대기'}>픽업대기</option>
                        <option value={'픽업완료'}>픽업완료</option>
                        <option value={'주문취소'}>주문취소</option>
                    </select>
                </div>
                <div className={'col-3 d-flex px-0 justify-content-end'}>
                    <input type="date"
                           className={'form-control mx-1 focus-ring focus-ring-dark text-decoration-none border-black startDate'}
                           name={'startDate'}
                           onChange={(event) => {
                               searchKeywordHandler(event);
                           }}
                    />
                    <span className={'pt-2'}>~</span>
                    <input type="date" className={'form-control mx-1 focus-ring focus-ring-dark text-decoration-none border-black endDate'}
                           name={'endDate'}
                           onChange={(event) => {
                               searchKeywordHandler(event);
                           }}
                    />
                </div>
                <div className={'col-4 d-flex px-0 justify-content-end'}>
                    <select className={'form-select-sm cate border-3 border-black rounded-4 searchCate'}
                            ref={selectedCate}
                            defaultValue={'memId'}>
                        <option value={'memName'}>회원명</option>
                        <option value={'memTell'}>연락처</option>
                        <option value={'memId'}>ID</option>
                        <option value={'storeName'}>상점</option>
                        <option value={'factoryName'}>공방</option>
                    </select>
                    <input style={{width: "200px"}} type={'text'}
                           placeholder={'검색어를 입력하세요'}
                           className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           value={keyword}
                           onChange={kw}
                    />
                </div>
                <div className={'col-1 px-0 d-flex justify-content-center'}>
                    <button className={'btn-sm btn btn-dark rounded-4'} onClick={getSearchExcel}>결과 저장</button>
                    <button className={'btn-sm btn btn-dark rounded-4'} onClick={getExcelData}>전체 저장</button>
                </div>
            </div>


            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    주문내역
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>No</td>
                            <td>회원ID</td>
                            <td>회원명</td>
                            <td>연락처</td>
                            <td>공방금액</td>
                            <td>주문상태</td>
                            <td>주문상점</td>
                            <td>주문상태변경</td>
                            <td>주문상태취소</td>
                            <td>주문상태</td>
                            <td>상세</td>
                        </tr>
                        </thead>
                        <tbody>
                        {orderList.map((order, idx) => (
                            <tr key={idx}>
                                <td>{order.orderNum}</td>
                                <td>{order.memId}</td>
                                <td>{order.memCode.memName}</td>
                                <td>{order.memCode.memTell}</td>
                                <td>{order.factoryTotalPrice.toLocaleString() + ' 원'}</td>
                                <td>{order.orderState}</td>
                                <td>{order.storeName}</td>
                                <td>
                                    {order.orderState === '입금대기' ?
                                        <button onClick={ () => {
                                            orderState('입금완료' ,order._id);
                                        }} className={'btn btn-sm btn-dark'}>입금완료</button>
                                        : order.orderState === '공방발송' ?
                                        <button onClick={ () => {
                                            orderState('제작중' ,order._id);
                                        }} className={'btn btn-sm btn-dark'}>제작중</button>
                                        : order.orderState === '제작중' ?
                                            <button value={'제작중'} onClick={() => {
                                                orderState('제작완료' ,order._id);
                                            }} className={'btn btn-sm btn-dark'}>제작완료</button>
                                            :  null
                                    }
                                </td>
                                <td>
                                    {order.orderState === '입금완료' ?
                                        <button onClick={() => {
                                            orderState('입금대기', order._id);
                                        }} className={'btn btn-sm btn-dark'}>입금취소</button>
                                        : order.orderState === '제작중' ?
                                            <button onClick={() => {
                                                orderState('공방발송', order._id);
                                            }} className={'btn btn-sm btn-dark'}>제작취소</button>
                                            : order.orderState === '제작완료' ?
                                                <button onClick={() => {
                                                    orderState('제작중', order._id);
                                                }} className={'btn btn-sm btn-dark'}>제작중</button>
                                                :
                                                null

                                    }
                                </td>
                                <td>{order.orderDate}</td>
                                <td>
                                    <button className={'btn btn-dark btn-sm rounded-4'} onClick={ () =>{ openModal(order)}}>상세</button>
                                </td>
                            </tr>
                        ))
                        }
                        </tbody>
                    </table>
                    <div className={'d-flex justify-content-center'}>
                        <nav aria-label="...">
                            <ul className="pagination pagination-sm">
                                {Array.from({length: totalPage}).map((_, index) => (
                                    <li className="page-item" key={index}>
                                        <a onClick={ () => { pageHandler(index + 1) }}
                                           className="page-link"
                                           style={{color: 'black'}}
                                           href="#">{index + 1}</a><
                                    /li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <OrderModal isModalOpen={isModalOpen} closeModal={closeModal} orderDetail={orderDetail}></OrderModal>
        </div>
    );
}

export default Factory;