import React, { useState, useEffect } from 'react';
import OrderModal from "./OrderModal";
import axios from "axios";
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";

function UserItem() {

    const [itemList, setItemList] = useState([]);
    const [modelPrice, setModelPrice] = useState();
    const [factoryPrice, setFactoryPrice] = useState();
    const [orderModalOpen, setOrderModalOpen] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [modelTotalPrice, setModelTotalPrice] = useState(0); // 모델 가격을 저장하는 상태
    const [factoryTotalPrice, setFactoryTotalPrice] = useState(0); // 공장 가격을 저장하는 상태
    const [user, setUser] = useRecoilState(userState);
    const [facInfo, setFacInfo] = useState({});


    useEffect(() => {
        getMyShop();
    }, []);

    useEffect(() => {
        // selectItems 배열이 변경될 때마다 모델 가격과 공장 가격을 다시 계산
        let newModelTotalPrice = 0;
        let newFactoryTotalPrice = 0;

        for (const selectedItem of selectedItems) {
            newModelTotalPrice += selectedItem.cnt * parseInt(selectedItem.modelPrice);
            newFactoryTotalPrice += selectedItem.cnt * parseInt(selectedItem.factoryPrice);
        }

        setModelTotalPrice(newModelTotalPrice);
        setFactoryTotalPrice(newFactoryTotalPrice);
    }, [selectedItems]);

    useEffect(() => { getItemList(); }, []);

    const getMyShop = async () => {
        const shopId = user.shopId;
        await axios.get(`/api/shop/user/${shopId}`).then(res => {
            getFacInfo(res.data);

        })
    }
    const getFacInfo = (myShop) => {
        axios.get(`/api/fac/info/${myShop.facCode}`).then((res => {
            setFacInfo(res.data);
        }))
    }
    const handleIncrement = (item) => {

        const itemId = item._id;
        let itemExists = false;

        // 선택된 아이템 배열을 확인하여 itemId와 일치하는 아이템을 찾습니다.
        const updatedItems = selectedItems.map((selectedItem) => {

            if (selectedItem._id === itemId) {
                itemExists = true;
                // 해당 아이템의 cnt를 증가시킵니다.
                return { ...selectedItem, cnt: selectedItem.cnt + 1 };
            }
            return selectedItem;
        });

        // itemExists가 false인 경우, 새로운 아이템을 배열에 추가합니다.
        if (!itemExists) {
            // 새로운 아이템은 기본값으로 cnt가 1인 상태로 추가합니다.
            updatedItems.push({ ...item, cnt: 1 });
        }

        // 상태를 업데이트합니다.
        setSelectedItems(updatedItems);

    }
    const handleDecrement = (item) => {
        const itemId = item._id;

        // 선택된 아이템 배열을 확인하여 itemId와 일치하는 아이템을 찾습니다.
        const updatedItems = selectedItems
            .map((selectedItem) => {
                if (selectedItem._id === itemId) {
                    // 해당 아이템의 cnt를 감소시킵니다.
                    const newCnt = Math.max(selectedItem.cnt - 1, 0);
                    return { ...selectedItem, cnt: newCnt };
                }
                return selectedItem;
            })
            .filter((selectedItem) => selectedItem.cnt > 0); // cnt가 0 이상인 아이템만 남깁니다.

        // 상태를 업데이트합니다.
        setSelectedItems(updatedItems);
    };


    const getItemList = async () => {
        await axios.get('/api/item')
            .then(result => {
                setItemList(result.data);
            })
            .catch(error => {

            });
    };

    const modalClose = () => {
        setOrderModalOpen(false);
    }

    const modalOpen = () => {
        if(selectedItems.length === 0) {
            alert('선택한 상품이 없습니다.');
            return
        }
        setOrderModalOpen(true);
    }

    return (
        <div className={'itemMenu mt-3'}>
            <h5>상품목록</h5>
            {!itemList ? <span>등록된 상품이 없습니다.</span> :
                itemList.map((item, idx) => {
                    const selectedItem = selectedItems.find((selectedItem) => selectedItem._id === item._id);
                    const cnt = selectedItem ? selectedItem.cnt : 0;
                    return (
                        <div className={'row py-1 border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-lg'} key={idx}>
                            <div className={'row mt-1'}>
                                <div className={'col-8 justify-content-start d-flex align-items-center px-0'}>
                                    <h5 style={{fontSize: '15px'}} className={'my-0'}>{item.modelName}</h5>
                                </div>
                                <div className={'col-3'}>
                                    <ul className="pagination pagination-sm my-0">
                                        <li className="page-item">
                                            <span className="page-link text-black" onClick={() => { handleDecrement(item) }}>-</span>
                                        </li>
                                        <li className="page-item">
                                            <span className="page-link text-black">{cnt}</span>
                                        </li>
                                        <li className="page-item" onClick={() => { handleIncrement(item) }}>
                                            <span className="page-link text-black">+</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col-4'}>
                                    <img src={'../../../../uploads/item/' + item.modelImg}
                                        className={'img-fluid rounded-4'} width={'150px'} alt={'itemImg'} />
                                </div>
                                <div className={'col-8 text-end'}>
                                    <span style={{ fontSize: '13px' }}>수공비용 : {item.factoryPrice.toLocaleString() + ' 원'}</span><br />
                                    <span style={{ fontSize: '13px' }}>재료가격 : {item.modelPrice.toLocaleString() + ' 원'}</span><br />
                                    <span style={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>  총  가격 : {(item.factoryPrice + item.modelPrice).toLocaleString() + ' 원'}</span>
                                </div>
                            </div>
                            <div className={'row d-flex justify-content-center'}>
                                <div className={'col mx-1'}>
                                    <span style={{ fontSize: '12px' }}>{item.leafName}</span>
                                </div>
                            </div>
                            <div className={'row d-flex justify-content-center'}>
                                <div className={'col mx-1'}>
                                    <span style={{ fontSize: '12px' }}>{item.tubeName}</span>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
            <div className={'row d-flex justify-content-center mt-2 fixed-bottom bg-white pt-2'} style={{ marginBottom: '75px', zIndex: '100'}}>
                <div className={'col-6 text-center'}>
                    <div className={'row'}>
                        <div className={'col'}>
                            <span style={{fontSize: '14px'}}>
                                수공비용 : {factoryTotalPrice.toLocaleString() + ' 원'}
                            </span>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col'}>
                            <span style={{fontSize: '14px'}}>
                                재료가격 : {modelTotalPrice.toLocaleString() + ' 원'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={'col-6 d-flex justify-content-center'}>
                    <button className={'btn btn-dark btn-sm rounded-4'} onClick={modalOpen}>주문하기</button>
                </div>
            </div>
            {
                orderModalOpen &&
                <OrderModal modalClose={modalClose}
                    selectedItems={selectedItems}
                    modelTotalPrice={modelTotalPrice}
                    factoryTotalPrice={factoryTotalPrice}
                            facInfo={facInfo}
                />
            }
        </div>

    );
}

export default UserItem;