import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import button from "bootstrap/js/src/button";
function Agree() {


    const rule1 =
        '1. 본 어플은 (주)두리누리의 담배피는 호랑이 수재담배 매장의 고객을 위한 어플입니다.\n' +
        '2. 본 어플은 수재담배 자체 제작을 어려워 하시는 고객을 위해 제휴공방에 작업 의뢰를 쉽게 하기위함을\n' +
        '목적으로 합니다. 어플에서 주문하신 결과물은 고객이 등록한 단골매장에서 수령하실 수 있습니다.\n' +
        '3. 본 어플을 통해 제작한 결과물은 유통 및 판매를 할 수 없습니다.'

    const rule2 = '본 어플을 이용함에 있어 [개인정보 보호법] 제15조 및 제17조에 따라 아래의 내용으로 개인정보를\n' +
        '수집, 이용 및 제공하는데 동의합니다.\n' +
        '개인정보의 이용 목적\n' +
        ' 수집된 개인정보는 (주)두리누리의 어플 서비스 및 프로모션 정보이용을 위해 활용하며,\n' +
        ' 목적 외의 용도로는 사용하지 않습니다. \n' +
        '\n' +
        '개인정보의 보관 및 이용 기간\n' +
        ' 회원 가입 부터 회원 탈퇴 까지로 하며 회원 탈퇴 후 1개월 이내 개인정보를 삭제합니다.\n' +
        '\n' +
        '수집항목\n' +
        '이름, 연락처, 이메일'


    const navigate = useNavigate();
    const [checkbox1, setCheckbox1] = useState(false);
    const [checkbox2, setCheckbox2] = useState(false);
    const handleCheckbox1Change = () => {
        setCheckbox1(!checkbox1);
    };

    const handleCheckbox2Change = () => {
        setCheckbox2(!checkbox2);
    };


    return (

        <div className={'container row mx-2 mb-5 d-flex justify-content-center mainFont'}>
            <div className={'col-12'}>
                <div className={'row mt-5 my-5 mb-3 d-flex justify-content-center'}>
                    <div className={'col d-flex justify-content-center'}>
                        <img src={'../../../../uploads/img/4.png'} className={'joinImg'}/>
                    </div>
                </div>

                <h1 className={'text-center mb-5'}>회원가입</h1>

                <div className={'row my-3'}>
                    <div className="col-12">
                        <div className="form-floating mb-1">
                            <textarea className="form-control" id="floatingTextarea2"
                                      style={{height: "100px", fontSize: '10px'}}
                                      disabled={true}
                                      value={rule1}></textarea>
                            <label htmlFor="floatingTextarea2">이용약관</label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input focus-ring focus-ring-dark bg-secondary border-dark"
                                   checked={checkbox1} onChange={handleCheckbox1Change}
                                   type="checkbox" value="" id="flexCheckChecked"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                동의합니다.
                            </label>
                        </div>
                    </div>
                </div>


                <div className={'row my-3'}>
                    <div className="col-12">
                        <div className="form-floating mb-1">
                            <textarea className="form-control" id="floatingTextarea2"
                                      style={{height: "100px", fontSize: '10px'}}
                                      disabled={true}
                                      value={rule2}></textarea>
                            <label htmlFor="floatingTextarea2">개인정보 수집 및 이용동의</label>
                        </div>

                        <div className="form-check">
                            <input className="form-check-input focus-ring focus-ring-dark bg-secondary border-dark"
                                   checked={checkbox2} onChange={handleCheckbox2Change}
                                   type="checkbox" value="" id="flexCheckChecked"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                동의합니다.
                            </label>
                        </div>
                    </div>
                </div>

                <div className={'row d-flex justify-content-center'}>
                    <div className={'col-10 d-grid'}>
                        <button

                            className={'btn btn-dark rounded-3'}
                            disabled={!checkbox1 || !checkbox2}
                            onClick={() => { navigate('/join'); }}>다음</button>
                    </div>
                </div>
        </div>
        </div>

    );
}

export default Agree;