import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate, useOutletContext} from "react-router-dom";



function Factory() {

    const navigate = useNavigate();
    const { myFact, getMyFact } = useOutletContext();
    const [facInfo, setFacInfo] = useState({});
    const [openTime, setOpenTime] = useState('');
    const [holiday, setHoliday] = useState('');
    const [tempInfo, setTempInfo] = useState('');

    useEffect( () => {
        getFacInfo();
    }, [])

    const getFacInfo = () => {
        axios.get(`/api/fac/info/${myFact._id}`).then((result) => {
            setFacInfo(result.data);
        })
    }

    const tempInfoHandler = () => {
        const sDate = document.querySelector('.sDate').value;
        const eDate = document.querySelector('.eDate').value;
        const reason = document.querySelector('.reason').value;

        setTempInfo(reason + ': ' + sDate + ' 부터 ' + eDate + ' 까지');
    }

    const setTemp = () => {
        const data = { tempInfo, }
        axios.patch(`/api/fac/${myFact._id}`, data).then(() => {
            getMyFact();
            alert('등록 성공')
        }).catch(
            alert('등록 실패')
        )
    }

    const resetTemp = () => {
        const data = { 'tempInfo': '' }
        axios.patch(`/api/fac/${myFact._id}`, data).then(() => {
            getMyFact();
        })
    }


    const timeHandler = () => {
        const sTime = document.querySelector('.startTime').value;
        const eTime = document.querySelector('.endTime').value;
        setOpenTime('오전 ' + sTime +'시 부터 ' + ' 오후 ' + eTime + '시')
    }

    const holidayHandler = (e) => {
        setHoliday(e.target.value);
    }

    const setBasicInfo = () => {
        const data = { openTime, holiday, }
        axios.patch(`/api/fac/${myFact._id}`, data).then(() => {
            getMyFact();
            alert('등록 성공')
        }).catch(
            alert('등록 실패')
        )
    }

    const logout = () => {
        axios.get('/api/member/logout').then(() => {
            navigate("/login");
        })
    }

    return (
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-8 px-0'}>
                    <h5>공방 정보</h5>
                    <div className={'row mb-5 border border-3 rounded-4 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">공방명</label>
                                <p>{myFact.name}</p>
                            </div>
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">점주</label>
                                <p>{myFact.owner}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8 text-start">
                                <label className="form-label mb-0">연락처</label>
                                <p>{myFact.tell}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8 text-start">
                                <label className="form-label mb-0">주소</label>
                                <p>{myFact.address1 + myFact.address2}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">은행</label>
                                <p>{myFact.accBK}</p>
                            </div>
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">예금주</label>
                                <p>{myFact.accName}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8 text-start">
                                <label className="form-label mb-0">계좌번호</label>
                                <p>{myFact.accNum}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">슈퍼바이저</label>
                                <p>{facInfo.svName}</p>
                            </div>
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">연락처</label>
                                <p>{facInfo.svTell}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">영업시간</label>
                                <p>{myFact.openTime}</p>
                            </div>
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">휴무일</label>
                                <p>{myFact.holiday}</p>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-6 text-start">
                                <label className="form-label mb-0">임시휴무</label>
                                <p>{myFact.tempInfo}</p>
                            </div>
                            <div className="col-2 align-bottom">
                                <button onClick={ resetTemp } className={'btn btn-dark btn-sm rounded-4'}>초기화</button>
                            </div>
                        </div>
                    </div>



                    <h5>임시 휴무일 설정</h5>
                    <div className={'row mb-5 border border-3 rounded-4 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">시작일</label>
                                <input type={'date'} onChange={tempInfoHandler} className={'form-control sDate'}/>
                            </div>
                            <div className="col-4 text-start">
                                <label className="form-label mb-0">종료일</label>
                                <input type={'date'} onChange={tempInfoHandler} className={'form-control eDate'}/>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8 text-start">
                                <label className="form-label mb-0">휴무사유</label>
                                <input type={'text'} onChange={tempInfoHandler} className={'form-control reason'} placeholder={'ex) 여름휴가'}/>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                                <button onClick={setTemp} className={'btn btn-dark'}>등록</button>
                            </div>
                        </div>
                    </div>
                    <h5>영업시간 설정</h5>
                    <div className={'row mb-5 border border-3 rounded-4 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className={'col-3'}>
                                <label className="form-label mb-0">오전</label>
                                <input type={'number'} onChange={timeHandler} className={'form-control startTime'} placeholder={''}/>
                            </div>
                            <div className={'col-2 d-flex align-items-end'}>
                                <span>시 부터</span>
                            </div>
                            <div className={'col-3'}>
                                <label className="form-label mb-0">오후</label>
                                <input type={'number'} onChange={timeHandler} className={'form-control endTime'} placeholder={''}/>
                            </div>
                            <div className={'col-2 d-flex align-items-end'}>
                                <span>시 까지</span>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">휴무일</label>
                                <input type={'text'} onChange={ holidayHandler } className={'form-control'} placeholder={'ex) 토,일요일 및 공휴일 , 매주 월요일'}/>
                            </div>

                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                                <button onClick={setBasicInfo} className={'btn btn-dark'}>등록</button>
                            </div>
                        </div>
                    </div>
                    <div className={'row mb-5 d-flex justify-content-center'}>
                        <div className={'col-10 d-flex justify-content-center'}>
                            <button className={'btn btn-dark rounded-4'} onClick={logout}>로그아웃</button>
                        </div>
                    </div>

                </div>
            </div>
    );
}

export default Factory;