import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axios from "axios";
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";

function ShopMember() {

    const navigate = useNavigate();

    const [user, setUser] = useRecoilState(userState);
    const [memberList, setMemberList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [myShop, setMyShop] = useState({});

    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [total, setTotal] = useState(1);

    useEffect(() => { getMyShop() }, []);

    useEffect(() => {
        getMyShop();
    }, [page]);

    const getMyShop = async () => {
        await axios.get(`/api/shop/${user._id}`).then(reuslt => {
            setMyShop(reuslt.data);
            searchMember(reuslt.data._id);
        })
    }

    const searchMember = async (_id) => {

        const idToUse = _id ? _id : myShop._id;

        await axios.get('/api/member/search?cate=shopId&keyword=' + idToUse + '&page=' + page)
            .then(result => {
                setMemberList([...result.data.result])
                setTotalPage(result.data.totalPage);
                setTotal(result.data.total);
            })
            .catch();
    }

    const pageHandler = (event) => {
        setPage(event.target.textContent);
    }


    return (
        <div className={'row d-flex justify-content-center'}>
            <div className={'col-10 px-0'}>
                <span>{'총 회원: ' + total}</span>
                <table className={'table table-striped text-center'}>
                    <thead>
                    <tr>
                        <td>No</td>
                        <td>회원ID</td>
                        <td>회원명</td>
                        <td>연락처</td>
                        <td>가입일시</td>
                    </tr>
                    </thead>
                    <tbody>
                    {memberList.map((member, idx) => (
                        <tr key={idx}>
                            <td>{idx + 1}</td>
                            <td>{member.memId}</td>
                            <td>{member.memName}</td>
                            <td>{member.memTell}</td>
                            <td>{member.regDate}</td>
                        </tr>
                    ))
                    }
                    </tbody>
                </table>
                <div className={'d-flex justify-content-center'}>
                    <nav aria-label="...">
                        <ul className="pagination pagination-sm">
                            {Array.from({length: totalPage}).map((_, index) => (
                                <li className="page-item" key={index}>
                                    <a onClick={ (event) => { pageHandler(event) }}
                                       className="page-link"
                                       style={{color: 'black'}}
                                       href="#">{index + 1}</a><
                                    /li>
                            ))}
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default ShopMember;