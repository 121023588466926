import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from './recoilState'; // 사용자의 권한 상태

const PrivateRoute = ({ roles, children }) => {
    const userRole = useRecoilValue(userState)?.memRole; // userState에서 .memRole 가져오기

    if (!userRole) {
        // 권한 정보가 없으면 로그인 페이지로 리디렉션
        return <Navigate to="/"/>;
    }

    if (roles && roles.indexOf(userRole) === -1) {
        // 사용자 권한이 허용된 권한 목록에 없으면 접근 거부 페이지로 리디렉션
        return <Navigate to="/access-denied" />;
    }

    // 권한이 허용되면 자식 컴포넌트 렌더링
    return children;
};

export default PrivateRoute;