import React, { useState, useEffect } from 'react';
import ItemModal from './ItemModal';
import axios from 'axios';
function Item() {

    const newData = {
        "modelName": "",
        "leafName": "",
        "tubeName": "",
        "caseName": "",
        "modelPrice": "",
        "factoryPrice": "",
        "modelImg": "",
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [mode, setMode] = useState();
    const [itemDetail, setItemDetail] = useState({});
    const [searchKeyword, setSearchKeyword] = useState({ cate: 'modelName' });

    useEffect(() => { getItemList(); }, []);
    useEffect(() => { getItemList(); }, [isModalOpen]);

    const getItemList = async () => {
        await axios.get('/api/admin/item')
            .then(result => {
                setItemList(result.data);
            })
            .catch(error => {

            });
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const openRegModal = () => {
        setMode('new');
        setItemDetail({...newData});
        setIsModalOpen(true);

    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openDetail = async (shopId) => {
        setMode('view');
        setItemDetail(shopId);
        openModal()
    }


    //검색어설정
    const updateSearchKeyword = (event) => {
        const value = event.target.value;
        let result = { cate: searchKeyword.cate, keyword: searchKeyword.keyword };
        event.target.className.includes("cate") ? result.cate = value : result.keyword = value;
        setSearchKeyword(result);
    };

    const updateIsUse = async (event, item) => {

        const updatedItem = { _id: item._id, isUse: !item.isUse };
        await axios.patch(`/api/admin/item/${item._id}`, updatedItem).then(
            result => {
                getItemList();
            }
        ).catch(err => {
            alert('잠시 후 다시 시도해주세요')
        });
        // item._id, item.isUse;

    };

    const search = async () => {

        await axios.get('/api/admin/item/search?cate=' + searchKeyword.cate + '&keyword=' + searchKeyword.keyword)
            .then(result => {
                setItemList(result.data)
            })
            .catch(err => {
                alert('잠시 후 다시 시도해주세요')
            });

    }


    return (
        <div className="mx-2">
            {/* 컨트롤영역 */}
            <div className={'row d-flex justify-content-center my-3'}>
                <div className={'col-4 d-flex justify-content-start px-0'}>
                    <button className={'btn btn-sm btn-dark rounded-4 mx-3 '} onClick={openRegModal}>상품등록</button>
                </div>
                <div className={'col-6 d-flex px-0 justify-content-end'}>
                    <button className={'btn btn-dark rounded-3 mx-2 border-3 rounded-4'} onClick={getItemList}>전체</button>
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center px-0'}>*/}
                    <select className={'form-select-sm cate border-3 border-black rounded-4'} onChange={updateSearchKeyword} defaultValue={'modelName'}>
                        <option value={'modelName'}>모델</option>
                    </select>
                    {/*</div>*/}
                    {/*<div className={'col-2 d-flex justify-content-center'}>*/}
                    <input style={{ width: "200px" }} type={'text'}
                        placeholder={'검색어를 입력하세요'}
                        className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                        onChange={updateSearchKeyword}
                    />
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center'}>*/}
                    <button className={'btn btn-dark rounded-4'} onClick={search}>검색</button>
                </div>
            </div>
            {/*상품목록영역*/}
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <table className={'table table-striped text-center'}>
                        <thead>
                            <tr>
                                <td>NO</td>
                                <td>사진</td>
                                <td>모델명</td>
                                <td>재료가격</td>
                                <td>공방가격</td>
                                <td>상세보기</td>
                                <td>사용여부</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                itemList.map((item, idx) => (
                                    <tr key={item._id}>
                                        <td>{idx + 1}</td>
                                        <td>
                                            <img className={'item-image'} src={'../../../uploads/item/' + item.modelImg} alt={'모델이미지'} />
                                        </td>
                                        <td>
                                            <span>
                                                {item.modelName}
                                            </span>
                                        </td>
                                        <td>{item.modelPrice.toLocaleString() + ' 원'}</td>
                                        <td>{item.factoryPrice.toLocaleString() + ' 원'}</td>
                                        <td>
                                            <button className={'btn btn-dark btn-sm rounded-4'}
                                                onClick={() => { openDetail(item) }}>상세보기</button>
                                        </td>
                                        <td>
                                            <div className="form-check form-switch justify-content-center align-items-center d-flex">
                                                <input className="form-check-input d-inline-flex focus-ring focus-ring-dark"
                                                    type="checkbox"
                                                    role="switch"
                                                    checked={item.isUse}
                                                    onChange={(event) => { updateIsUse(event, item) }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <ItemModal isOpen={isModalOpen} onClose={closeModal} mode={mode} itemDetail={itemDetail}/>
        </div>
    );
}

export default Item;