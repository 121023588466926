import React from 'react';
import axios from "axios";


function OrderModal({isModalOpen, closeModal, orderDetail }) {


    const orderCancel = async () => {
        await axios.patch(`/api/order/cancel/${orderDetail._id}`).then(() => {
            alert('주문 취소 성공');
            closeModal();
        })
    }

    if (!isModalOpen) return null;

    return (
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background mt-5">
                <div className="item-modal">
                    <h3 className={'text-center'}>주문 상세</h3>
                    <div className={'row my-5 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">주문회원ID</label>
                                <h5>{orderDetail.memId}</h5>
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0">주문번호</label>
                                <h5>{orderDetail.orderNum}</h5>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">주문상점</label>
                                <h5>{orderDetail.storeName}</h5>
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0">제작공방</label>
                                <h5>{orderDetail.factoryName}</h5>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">주문상품</label>
                                <table className={'table'}>
                                    <thead>
                                        <tr>
                                            <td>모델명</td>
                                            <td>수량</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {orderDetail.model.map((item) => (
                                        <tr>
                                            <td>{item.modelName}</td>
                                            <td>{item.cnt}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">공방금액</label>
                                <h5>{orderDetail.factoryTotalPrice.toLocaleString() + ' 원'}</h5>
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0">재료가격</label>
                                <h5>{orderDetail.modelTotalPrice.toLocaleString() + ' 원'}</h5>
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">

                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">총 결제금액</label>
                                <h5>{(orderDetail.factoryTotalPrice + orderDetail.modelTotalPrice).toLocaleString() + ' 원'}</h5>
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0"></label>
                                <h5></h5>
                            </div>
                        </div>

                        <div className="row d-flex my-1 justify-content-center">

                        </div>                       
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">주문일시</label>
                                <h5>{orderDetail.orderDate}</h5>
                            </div>
                        </div>                        
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <label className="form-label mb-0">상품상태</label>
                                <h5>{orderDetail.orderState}</h5>
                            </div>
                            <div className="col-5">
                                <button className={'btn btn-sm btn-dark rounded-4'} onClick={orderCancel}>주문취소</button>
                            </div>
                        </div>


                    </div>
                    <div className="row d-flex mt-3 justify-content-center">
                        <div className="col-10 d-flex justify-content-center">
                           <button className={'btn btn-sm btn-dark rounded-4'} onClick={closeModal}>닫기</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default OrderModal;