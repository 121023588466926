import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import axios from "axios";
import { useRecoilState } from 'recoil';
import { userState, notificationTokenState } from './recoilState';
import {getToken ,messaging} from "../firebase";

function Login({setIsPWA,}) {

    const navigate = useNavigate();
    const [token, setToken] = useRecoilState(notificationTokenState);


    const [member, setMember] = useState({
        memId: '',
        memPw: '',
    });

    const [findId, setFindId] = useState({
        memName: '',
        email: '',
    });


    const [findPw, setFindPw] = useState({
        memId: '',
        memName: '',
        email: '',
    });

    const [user, setUser] = useRecoilState(userState);
    const [findIdModal, setFindIdModal] = useState(false);
    const [findPwModal, setFindPwModal] = useState(false);


    //
    //
    // const test = async () => {
    //
    //     await axios.get('/api/order/test11');
    //
    // }
    const find = async () => {
        await axios.post('/api/member/findId', findId).then(result => {
        }).catch(err => {
            alert(err.response.data);
        });
    }

    const resetPw = async () => {

        await axios.post('/api/member/resetPw', findPw).then(result => {
            alert('비밀번호 초기화 성공');

        }).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });
    }

    const submit = async () => {
        try {
            const result = await axios.post('/api/member/login', member);
            await setUser(result.data.user);
            fbToken(result.data.user._id)
            navigate('/');
        } catch (err) {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        }
    }

    const fbToken = async (id) => {
        const fbToken = {
            'fbToken': token
        }
        await axios.patch(`/api/member/${id}`, fbToken).then()

    }


    const updateMemInfo = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setMember(prevMember => ({
            ...prevMember,
            [key]: value
        }));
    }

    const updateFindId = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setFindId(prevMember => ({
            ...prevMember,
            [key]: value
        }));
    }

    const updateFindPw = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setFindPw(prevMember => ({
            ...prevMember,
            [key]: value
        }));
    }
    const down = () => {
        // setIsPWA(false);
        navigate("/download");
    }


    return (
        <div className={'container'}>
            <span onClick={down} style={{fontSize: '9px', color: 'gray'}}>설치 안내페이지 이동</span>
            <div className={'row d-flex justify-content-center my-5 mt-5 mainFont'}>
                <div className={'col-9'}>
                    <div className={'row mt-5 my-5 mb-5 d-flex justify-content-center'}>
                        <div className={'col d-flex justify-content-center'}>
                            <img src={'../../../../uploads/img/1.png'} className={'loginImg'}/>
                        </div>
                    </div>
                    <div className={'row mt-5 d-flex justify-content-center'}>
                        <div className={'col-md-6'}>
                            <label>ID</label>
                            <input type={'text'}
                                className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                name={'memId'}
                               value={member.memId}
                                onChange={updateMemInfo}
                            />
                        </div>
                    </div>
                    <div className={'row mb-4 d-flex justify-content-center'}>
                        <div className={'col-md-6'}>
                            PASSWORD
                            <input type={'password'}
                                className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                name={'memPw'}
                                   value={member.memPw}

                                onChange={updateMemInfo}
                            />
                        </div>
                    </div>
                    <div className={'row my-2 d-flex justify-content-center'}>
                        <div className={'col-md-6 d-grid'}>
                            <button className={'btn btn-dark rounded-3'} onClick={submit}>로그인</button>
                        </div>
                    </div>
                    <div className={'row my-2 d-flex justify-content-center'}>
                        <div className={'col-md-6 d-grid'}>
                            <button className={'btn btn-dark rounded-3'} onClick={() => { navigate('/agree'); }}>회원가입</button>
                        </div>
                    </div>
                    <div className={'row my-2 mt-3 d-flex justify-content-center'}>
                        <div className={'col-6 text-start'}>
                            <span onClick={ ()=>{ setFindIdModal(!findIdModal) } } className={'text-start'} style={{cursor: 'pointer', fontSize: '14px'}}>아이디 찾기 </span>
                        </div>
                        <div className={'col-6 text-end'}>
                            <span onClick={ ()=>{ setFindPwModal(!findPwModal) } } className={'text-end'} style={{cursor: 'pointer', fontSize: '14px'}}> 비밀번호 찾기</span>
                        </div>
                    </div>
                    {findIdModal ?
                        <div className={'row my-2 mt-3 d-flex justify-content-center'}>
                        <div className={'row mb-4 d-flex justify-content-center'}>
                            <div className={'col-md-6 d-grid'}>
                                <h5 className={'text-center'}>아이디 찾기</h5>
                                <label>이름</label>
                                <input type={'text'}
                                       className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                       name={'memName'}
                                       onChange={updateFindId}
                                />
                                </div>
                        </div>
                        <div className={'row mb-4 d-flex justify-content-center'}>
                            <div className={'col-md-6 d-grid'}>
                                <label>이메일</label>
                                <input type={'email'}
                                       className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                       name={'email'}
                                       onChange={updateFindId}
                                />
                                <span style={{fontSize: '8px'}}>가입시 등록한 이메일로 아이디가 전송됩니다.</span>
                            </div>
                        </div>


                        <div className={'row mb-4 d-flex justify-content-center'}>
                            <div className={'col-md-6 d-grid'}>
                                <button className={'btn btn-dark rounded-3'} onClick={find}>찾기</button>
                            </div>
                        </div>
                        </div>
                : null }

                    {findPwModal ?
                    <div className={'row my-2 mt-3 d-flex justify-content-center'}>
                        <h5 className={'text-center'}>비밀번호 찾기</h5>
                        <div className={'row d-flex justify-content-center'}>
                            <div className={'col-md-6'}>
                                <label>아이디</label>
                                <input type={'text'}
                                       className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                       name={'memId'}
                                       onChange={updateFindPw}
                                />
                            </div>
                        </div>
                        <div className={'row d-flex justify-content-center'}>
                            <div className={'col-md-6'}>
                                <label>이름</label>
                                <input type={'text'}
                                       className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                       name={'memName'}
                                       onChange={updateFindPw}
                                />
                            </div>
                        </div>
                        <div className={'row mb-4 d-flex justify-content-center'}>
                            <div className={'col-md-6'}>
                                이메일
                                <input type={'email'}
                                       className={'form-control focus-ring focus-ring-dark text-decoration-none border'}
                                       name={'email'}
                                       onChange={updateFindPw}
                                />
                            <span style={{fontSize: '8px'}}>가입시 등록한 이메일로 초기화된 비밀번호가 전송됩니다.</span>
                            </div>
                        </div>
                        <div className={'row mb-4 d-flex justify-content-center'}>
                            <div className={'col-md-6 d-grid'}>
                                <button className={'btn btn-dark rounded-3'} onClick={resetPw}>초기화</button>
                            </div>
                        </div>
                    </div>
                    : null }

                </div>
            </div>
        </div>
    );
}

export default Login;