import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import OrderModal from "./OrderModal";
import {writeFile, utils} from 'xlsx';

function AdminOrder() {


    const [orderList, setOrderList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [orderDetail, setOrderDetail] = useState({});
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [total, setTotal] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState({'startDate': new Date(0).toISOString(), 'endDate': new Date().toISOString()});
    const selectedCate = useRef();

    useEffect(() => {
        searchOrder();

    }, [])

    useEffect(() => {
        searchOrder();
    }, [page])

    useEffect(() => {
        searchOrder();
    }, [isModalOpen])

    useEffect(() => {
        searchOrder();
    }, [keyword])

    useEffect(() => {
        searchOrder();
    }, [searchKeyword]);

    const resetKeyword = () => {
        setKeyword('');
        const keysToKeep = ['startDate', 'endDate']; // 유지하려는 키 목록
        const updatedKeyword = Object.keys(searchKeyword)
            .filter(key => keysToKeep.includes(key)) // 유지해야 할 키만 필터링
            .reduce((obj, key) => {
                obj[key] = searchKeyword[key]; // 유지해야 할 키만 새 객체에 복사
                return obj;
            }, {
                'startDate': new Date(0).toISOString(),
                'endDate': new Date().toISOString(),
            });

        setSearchKeyword(updatedKeyword);
    }

    const getExcelData = () => {
        axios.get('/api/order').then(result => {
            download(result.data)
        })
    }
    const getSearchExcel = () => {

        const cate = selectedCate.current.value;
        const payload = searchKeyword;
        payload[cate] = keyword;

        axios.post('/api/order/searchExcel', payload).then(result => {
            download(result.data)
        })
    }
    const download = (excel) => {

        let result = [];
        excel.forEach((order) => {
            const data = {
                '회원ID': order.memId,
                '회원명': order.memCode.memName,
                '연락처': order.memCode.memTell,
                '재료가격': order.modelTotalPrice,
                '공방금액': order.factoryTotalPrice,
                '상점명': order.storeName,
                '공방명': order.factoryName,
                '주문상태': order.orderState,
                '주문일시': order.orderDate,
            }
            result.push(data);
        })

        const ws = utils.json_to_sheet(result);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Sheet1");
        writeFile(wb, "주문목록.xlsx");
    }
    const reset = () => {
        resetKeyword();
        document.querySelector('.orderState').value = '전체'
        document.querySelector('.startDate').value = null
        document.querySelector('.endDate').value = null
        setPage(1);
    }

    const kw = (event) => {
        resetKeyword();
        setKeyword(event.target.value);
    }

    const openModal = (item) => {
        setOrderDetail(item)
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        resetKeyword();
        // getOrderList();
    };
    const pageHandler = (number) => {
        setPage(number);
    }

    const searchKeywordHandler = (event) => {

        const cate = selectedCate.current.value;

        if (event) {
            if(event.target.value == '전체'){
                reset()
                return
            }

            setSearchKeyword(prev => ({
                ...prev,
                'page': page,
                [event.target.name]: event.target.value,
                [cate]: keyword,
            }));
        }
        else {
            setSearchKeyword(prev => ({
                ...prev,
                'page': page,
                [cate]: keyword,
            }));

        }
    }

    const searchOrder = async () => {
        const cate = selectedCate.current.value;

        const payload = searchKeyword;
        payload[cate] = keyword;
        payload.page = page;

        await axios.post('/api/order/searchOrder', payload).then(result => {
            setOrderList(result.data.result);
            setTotalPage(result.data.totalPage);
            setTotal(result.data.total);
        }).catch(e => {
            setOrderList([]);
            setTotalPage(1);
        })
    }

    return (
        <div>
            <div className={'row d-flex justify-content-center my-2'}>
                <div className={'col-1 px-0 d-flex justify-content-center'}>
                    <button className={'btn btn-dark rounded-4'} onClick={reset}>초기화</button>
                </div>
                <div className={'col-1 px-2'}>
                    <select className={'form-select orderState'}
                            name={'orderState'}
                            value={searchKeyword.orderState}
                            onChange={(event) => {
                                searchKeywordHandler(event);
                            }}>
                        <option value={'전체'}>전체</option>
                        <option value={'입금대기'}>입금대기</option>
                        <option value={'입금완료'}>입금완료</option>
                        <option value={'공방발송'}>공방발송</option>
                        <option value={'제작중'}>제작중</option>
                        <option value={'제작완료'}>제작완료</option>
                        <option value={'픽업대기'}>픽업대기</option>
                        <option value={'픽업완료'}>픽업완료</option>
                        <option value={'주문취소'}>주문취소</option>
                    </select>
                </div>
                <div className={'col-3 d-flex px-0 justify-content-end'}>
                    <input type="date" className={'startDate form-control mx-1 focus-ring focus-ring-dark text-decoration-none border-black'}
                           name={'startDate'}
                           onChange={(event) => {
                               searchKeywordHandler(event);
                           }}
                    />
                    <span className={'pt-2'}>~</span>
                    <input type="date" className={'endDate form-control mx-1 focus-ring focus-ring-dark text-decoration-none border-black'}
                           name={'endDate'}
                           onChange={(event) => {
                               searchKeywordHandler(event);
                           }}
                    />
                </div>
                <div className={'col-3 d-flex px-0 justify-content-end'}>
                    <select className={'form-select-sm cate border-3 border-black rounded-4 searchCate'}
                            ref={selectedCate}
                            defaultValue={'memId'}>
                        <option value={'memName'}>회원명</option>
                        <option value={'memTell'}>연락처</option>
                        <option value={'memId'}>ID</option>
                        <option value={'storeName'}>상점</option>
                        <option value={'factoryName'}>공방</option>
                    </select>
                    <input style={{width: "200px"}} type={'text'}
                           placeholder={'검색어를 입력하세요'}
                           className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           value={keyword}
                           onChange={kw}
                    />
                    {/*<button onClick={searchOrder} className={'btn btn-dark rounded-4'}>검색</button>*/}
                </div>
                <div className={'col-2 px-0 d-flex justify-content-center'}>
                    <button className={'btn-sm btn btn-dark rounded-4 mx-1'} onClick={getSearchExcel}>결과 저장</button>
                    <button className={'btn-sm btn btn-dark rounded-4'} onClick={getExcelData}>전체 저장</button>
                </div>
            </div>

            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <h5>주문목록</h5>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>주문번호</td>
                            <td>회원ID</td>
                            <td>회원명</td>
                            <td>연락처</td>
                            <td>주문상태</td>
                            <td>재료가격</td>
                            <td>공방금액</td>
                            <td>상점명</td>
                            <td>공방명</td>
                            <td>주문일시</td>
                            <td>상세</td>
                        </tr>
                        </thead>
                        <tbody>
                        {orderList.map((order, idx) => (
                            <tr key={idx}>
                                <td>{order.orderNum}</td>
                                <td>{order.memId}</td>
                                <td>{order.memCode.memName}</td>
                                <td>{order.memCode.memTell}</td>
                                <td>{order.orderState}</td>
                                <td>{order.modelTotalPrice.toLocaleString() + ' 원'}</td>
                                <td>{order.factoryTotalPrice.toLocaleString() + ' 원' }</td>
                                <td>{order.storeName}</td>
                                <td>{order.factoryName}</td>
                                <td>{order.orderDate}</td>
                                <td>
                                    <button className={'btn btn-dark btn-sm rounded-4'} onClick={ () =>{ openModal(order)}}>상세</button>
                                </td>
                            </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <div className={'d-flex justify-content-center'}>
                        <nav aria-label="...">
                            <ul className="pagination pagination-sm">
                                {Array.from({length: totalPage}).map((_, index) => (
                                    <li className="page-item" key={index}>
                                        <a onClick={ () => { pageHandler(index + 1) }}
                                           className="page-link"
                                           style={{color: 'black'}}
                                           href="#">{index + 1}</a><
                                    /li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <OrderModal isModalOpen={isModalOpen} closeModal={closeModal} orderDetail={orderDetail}></OrderModal>
        </div>
    );
}

export default AdminOrder;