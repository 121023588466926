import React, {useState, useEffect} from 'react';
import axios from 'axios';

function Member() {

    const [memberList, setMemberList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [total, setTotal] = useState(1);

    const getMemberList = async () => {

        await axios.get(`/api/member?page=${page}`)
            .then(result => {
                setMemberList([...result.data.result]);
                setTotalPage(result.data.totalPage);
                setTotal(result.data.total);
            })
            .catch(error => {

            });

    };
    //
    // //검색어설정
    const updateSearchKeyword = (event) => {
        const value = event.target.value;
        const cate = document.querySelector('.searchCate').value;
        let result = {cate: cate, keyword: searchKeyword.keyword};
        event.target.className.includes("cate") ? result.cate = value : result.keyword = value;
        setSearchKeyword(result);
    };

    const updateRole = async (event, member) => {

        const updateMember = {_id: member._id, [event.target.name]: event.target.value};
        await axios.patch(`/api/member/${member._id}`, updateMember).then(
            result => {
                getMemberList();
            }
        ).catch();

    };

    const updateIsUse = async (event, member) => {

        const updateMember = {_id: member._id, isUse: !member.isUse};
        await axios.patch(`/api/member/${member._id}`, updateMember).then(
            result => {
                getMemberList();

            }
        ).catch();
    };

    const search = async () => {
        await axios.get('/api/member/search?cate=' + searchKeyword.cate + '&keyword=' + searchKeyword.keyword)
            .then(result => {
                setMemberList([...result.data])
            })
            .catch();
    }

    const pageHandler = (event) => {
        setPage(event.target.textContent);
    }

    useEffect(() => {
        getMemberList();
    }, []);

    useEffect(() => {
        getMemberList();
    }, [page]);


    useEffect(() => {
        if (searchKeyword != null && searchKeyword != undefined && searchKeyword != {}) {
            search();
        }
    }, [searchKeyword]);

    return (
        <>
            {/* 컨트롤영역 */}

            <div className={'row d-flex justify-content-center my-3'}>
                <div className={'col-5'}>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={getMemberList}>전체
                    </button>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={() => {
                                setSearchKeyword({cate: 'memRole', keyword: 'user'});
                            }}>일반
                    </button>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={() => {
                                setSearchKeyword({cate: 'memRole', keyword: 'factory'});
                            }}>공방
                    </button>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={() => {
                                setSearchKeyword({cate: 'memRole', keyword: 'shop'});
                            }}>상점
                    </button>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={() => {
                                setSearchKeyword({cate: 'memRole', keyword: 'sv'});
                            }}>슈퍼바이저
                    </button>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-1 border-3 rounded-4'}
                            onClick={() => {
                                setSearchKeyword({cate: 'memRole', keyword: 'admin'});
                            }}>관리자
                    </button>
                </div>
                <div className={'col-5 d-flex px-0 justify-content-end'}>
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center px-0'}>*/}
                    <select className={'form-select-sm cate border-3 border-black rounded-4 searchCate'}
                            onChange={updateSearchKeyword} defaultValue={'name'}>
                        <option value={'memName'}>이름</option>
                        <option value={'memId'}>ID</option>
                    </select>
                    {/*</div>*/}
                    {/*<div className={'col-2 d-flex justify-content-center'}>*/}
                    <input style={{width: "200px"}} type={'text'}
                           placeholder={'검색어를 입력하세요'}
                           className={'d-inline-flex mx-2 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           onChange={updateSearchKeyword}
                    />
                    {/*</div>*/}
                    {/*<div className={'col-1 d-flex justify-content-center'}>*/}
                    <button className={'btn btn-dark rounded-4'} onClick={search}>검색</button>
                </div>
            </div>

            {/*상품목록영역*/}
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <span>{'총 회원: ' + total}</span>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>NO</td>
                            <td>ID</td>
                            <td>회원명</td>
                            <td>연락처</td>
                            <td>권한</td>
                            <td>사용여부</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            memberList.map((member, idx) => (
                                <tr key={member._id}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <span>{member.memId}</span>
                                    </td>
                                    <td>
                                            <span>
                                                {member.memName}
                                            </span>
                                    </td>
                                    <td>{member.memTell}</td>
                                    <td>
                                        <select name={'memRole'}
                                                className={'form-select-sm cate border-3 border-black rounded-4'}
                                                onChange={(event) => {
                                                    updateRole(event, member)
                                                }}
                                                defaultValue={member.memRole}>
                                            <option value={'user'}>일반</option>
                                            <option value={'shop'}>상점</option>
                                            <option value={'factory'}>공방</option>
                                            <option value={'sv'}>슈퍼바이저</option>
                                            <option value={'admin'}>관리자</option>
                                        </select>
                                    </td>
                                    <td>
                                        <div
                                            className="form-check form-switch justify-content-center align-items-center d-flex">
                                            <input className="form-check-input d-inline-flex focus-ring focus-ring-dark"
                                                   type="checkbox"
                                                   name={'isUse'}
                                                   role="switch"
                                                   checked={member.isUse}
                                                   onChange={(event) => {
                                                       updateIsUse(event, member)
                                                   }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <div className={'d-flex justify-content-center'}>
                        <nav aria-label="...">
                            <ul className="pagination pagination-sm">
                                {Array.from({length: totalPage}).map((_, index) => (
                                    <li className="page-item" key={index}>
                                        <a onClick={ (event) => { pageHandler(event) }}
                                           className="page-link"
                                           style={{color: 'black'}}
                                           href="#">{index + 1}</a><
                                    /li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Member;