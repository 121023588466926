import React from "react";
import { Outlet } from "react-router-dom";
import Menubar from "./MenuBar"

function User() {

    return (
        <>
        <nav className="navbar bg-body-tertiary fixed-top mb-2 ps-2">
            <img className={'headerImg mb-1'} src={'../../../../uploads/img/2.png'}/>
        </nav>
        <div className={'container-fluid'}>
            <div className="row" style={{ paddingTop: '60px', paddingBottom: '150px' }}>
                <Outlet/>
            </div>
            <div className="menu-bar bg-white fixed-bottom" style={{ height: '75px' }}>
                <Menubar></Menubar>
            </div>
        </div>
        </>
    );
}

export default User;