import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { useRecoilState } from 'recoil';
import { userState } from "./recoilState";


function AuthRedirect() {
    const navigate = useNavigate();
    const [user, setUser] = useRecoilState(userState);


    useEffect(() => {
        const checkAuthentication = async () => {
            // 리프레시 토큰을 가져옵니다.
            const refreshToken = await Cookies.get("refreshToken");

            // 쿠키와 리프레시 토큰이 없으면 로그인 페이지로 이동합니다.
            if (!refreshToken) {
                navigate("/login");
                return;
            }

            // 서버로 리프레시 토큰을 보내서 인증을 확인합니다.
            try {
                const response = await axios.get("/api/member/refresh");
                await setUser(response.data.user);

                // 인증이 성공하면, 회원 권한에 따라 다른 페이지로 리다이렉트합니다.
                if (response.data.user.memRole === "admin") {
                    navigate("/admin/item");
                } else if (response.data.user.memRole === "user") {
                    navigate("/user/userHome");
                } else if (response.data.user.memRole === "shop") {
                    navigate("/shop/orderList");
                } else if (response.data.user.memRole === "factory") {
                    navigate("/factory/orderList");
                } else {
                    // 다른 권한 또는 오류 처리
                    navigate("/login");
                }
            } catch (error) {
                // 인증 실패 또는 오류 처리
                navigate("/login");
            }
        };

        checkAuthentication();
    }, [navigate]);

    return null;
}

export default AuthRedirect;