import {atom} from 'recoil';
import { useRecoilState } from 'recoil';
import Cookies from 'js-cookie';

const getUserFromCookie = () => {
    const userCookie = Cookies.get('userState');
    if (userCookie) {
        return JSON.parse(decodeURIComponent(userCookie));
    }
    return null;
};

export const userState = atom({
    key: 'member',
    default: getUserFromCookie() || null, // 쿠키가 있으면 쿠키 내용을 사용, 없으면 null
});

export const notificationTokenState = atom({
    key: 'notificationTokenState',
    default: null,
});
