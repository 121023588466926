import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {userState} from "./recoilState";
import axios from "axios";

function ChangePw({setShowChangePw}) {


    const [user, setUser] = useRecoilState(userState);

    const initData = {
        memId: user.memId,
        prevPw: '',
        newPw: '',
        newPwChk: '',
    }

    const [changePw, setChangePw] = useState(initData);
    const [pwCheck, setPwCheck] = useState(false);
    const [pwInput, setPwInput] = useState(false);
    const [pwDupleCheck, setPwDupleCheck] = useState(false);
    const [allCheck, setAllCheck] = useState(false);

    useEffect( () => {
        pwDupleChk();
    }, [changePw.newPwChk]);

    useEffect( () => {
        pwChk();
        setPwInput(true);
    }, [changePw.newPw]);

    useEffect( () => {
        if(pwDupleCheck && pwInput && pwCheck){
            setAllCheck(true);
        }
        else {
            setAllCheck(false);
        }
    }, [pwInput, pwDupleCheck, pwCheck]);
    const pwChk = () => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        regex.test(changePw.newPw) ? setPwCheck(true) : setPwCheck(false);
    }

    const pwDupleChk = () => {
        changePw.newPw === changePw.newPwChk ? setPwDupleCheck(true) : setPwDupleCheck(false)
    }

    const updateMemInfo = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setChangePw(prevMember => ({
            ...prevMember,
            [key]: value
        }));
    }

    const updatePw = async () => {

        await axios.post('/api/member/changePw', changePw).then(result => {
            alert('비멀번호 변경 완료');
            setShowChangePw(false);
            setChangePw(initData);
        }).catch((err) => {
            alert(err.response.data);
            setChangePw(initData);
            setShowChangePw(false);
        })
    }


    return (
        <>
            <div className={'row my-2'}>
                <div className="col-8">
                    <label className="form-label mb-0">기존 비밀번호</label>
                    <input type="password"
                           className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                           name={'prevPw'}
                           value={changePw.prevPw}
                           onChange={updateMemInfo}
                    />
                </div>
            </div>
            <div className={'row my-2'}>
                <div className="col-8">
                    <label className="form-label mb-0">변경할 비밀번호</label>
                    <input type="password"
                           className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                           name={'newPw'}
                           value={changePw.newPw}
                           onChange={updateMemInfo}
                    />
                </div>
                {pwInput && pwCheck ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>사용가능</span> : <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>영문+숫자 8글자 이상</span>
                }
            </div>
            <div className={'row'}>
                <div className="col-8 mb-2">
                    <label className="form-label mb-0">비밀번호 확인</label>
                    <input type="password"
                           className={"d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"}
                           name={'newPwChk'}
                           value={changePw.newPwChk}
                           onChange={updateMemInfo}
                    />
                </div>
                {pwDupleCheck ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>확인완료</span> : null}
            </div>
            <div className={'row my-3 d-flex justify-content-center'}>
                <div className="col-8 mb-2 d-grid">
                  <button disabled={!allCheck} onClick={updatePw} className={'btn btn-sm btn-dark rounded-4'}>변경</button>
                </div>
            </div>
        </>
    );
}

export default ChangePw;