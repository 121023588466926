// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getToken, getMessaging } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD4G6Vx4jhPf6oq5O_QNspZgeXTRJIdfRI",
    authDomain: "damho-907e3.firebaseapp.com",
    projectId: "damho-907e3",
    storageBucket: "damho-907e3.appspot.com",
    messagingSenderId: "818459332189",
    appId: "1:818459332189:web:fecc461d0170132c1447b4",
    measurementId: "G-MP1RLTH9VD"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging();
window.onload = () => {
    if (!("Notification" in window)) {
    } else {
        Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
                // 알림 권한을 획득한 경우
                getToken(messaging, {
                    vapidKey:
                        "BNqG3mV1oBU3kIw2QUQ0R3v-KljEtU7HroqQbM7tUHoXD-ig9JlHQBWnI7G9LQQayiGMHllssRKvJQaNrUrFMeU",
                })
                    .then(async (currentToken) => {
                        if (currentToken) {
                            // 토큰을 가져옴
                        } else {
                            // 토큰을 가져올 수 없는 경우
                            // ...
                        }
                    })
                    .catch((err) => {
                        // console.log("토큰을 가져오는 동안 오류 발생. ", err);
                    });
            } else if (permission === "denied") {
                // 알림 권한을 거부한 경우
            } else {
                // 알림 권한이 요청되지 않은 경우
                // alert("알림 권한이 요청되지 않았습니다.");
            }
        });
    }
};

export { messaging, getToken,  };