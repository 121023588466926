import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userState } from "../recoilState"
import OrderedModal from "./OrderedModal";

function OrderList() {

    const [orderList, setOrderList] = useState([]);
    const [user, setUser] = useRecoilState(userState);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectOrder, setSelectOrder] = useState({});

    useEffect(() => { getOrderList() }, []);

    const modalClose = () => {
        setIsModalOpen(false);
    }

    const modalOpen = (order) => {
        setSelectOrder(order);
        setIsModalOpen(true);
    }

    const getOrderList = async () => {
        const memId = user._id;
        await axios.get(`/api/order/user/${memId}`).then(res => {
            setOrderList(res.data);
        })
    }


    return (
        <div className="mt-3">
            <h5>주문내역</h5>
            {orderList.length === 0 ? <span>주문내역이 없습니다.</span> :
                orderList.map((order) => (
                <div key={order._id}
                     className={'row border-3 border border-dark py-2 rounded-4 mx-1 my-3 d-flex justify-content-center shadow-lg'}
                    onClick={ () => {
                        modalOpen(order);
                    } }
                >
                    <h5>{order.orderState === '공방발송' || order.orderState === '제작중' || order.orderState === '제작완료' ? '제작중' : order.orderState}</h5>
                    {order.model.map(o => (
                        <div className={'row my-1 d-flex justify-content-center'} key={o.modelId}>
                            <div className={'col justify-content-center d-flex align-items-center'}>
                                <span key={o.modelId}>{o.modelName} : {o.cnt}개</span>
                            </div>
                        </div>
                    ))}
                    <div className={'row my-1 d-flex justify-content-center'}>
                        <div className={'col justify-content-center d-flex align-items-center'}>
                            <span>공방가격 : {order.factoryTotalPrice.toLocaleString() + ' 원'}</span>
                        </div>
                    </div>
                    <div className={'row my-1 d-flex justify-content-center'}>
                        <div className={'col justify-content-center d-flex align-items-center'}>
                            <span>재료가격 : {order.modelTotalPrice.toLocaleString() + ' 원'}</span>
                        </div>
                    </div>
                    <div className={'row my-1 d-flex justify-content-center'}>
                        <div className={'col justify-content-center d-flex align-items-center'}>
                            <span style={{ fontSize: '12px' }}>주문일시 : {order.orderDate}</span>
                        </div>
                    </div>
                </div>
            ))
            }
            {isModalOpen &&
                <OrderedModal selectOrder={selectOrder} modalClose={modalClose}></OrderedModal>
            }
        </div>
    );
}

export default OrderList;