import { Link, useNavigate } from "react-router-dom";
function ShopHeader() {

    const navigate = useNavigate();

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">담배피는 호랑이 (상점페이지)</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <Link to={'/shop/orderList'} className="nav-link">주문관리</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/shop/shopMember'} className="nav-link">회원목록</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <Link to={'/shop/shopInfo'} className="nav-link">상점정보</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default ShopHeader;