import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from 'recoil';
import { userState } from "../recoilState"
import FindPw from "../ChangePw";

function MyInfo() {

    const navigate = useNavigate();
    const [shopList, setShopList] = useState([]);
    const [user, setUser] = useRecoilState(userState);
    const [editShop, setEditShop] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const [myInfo, setMyInfo] = useState({});
    const [myShop, setMyShop] = useState({});
    const [showChangePw, setShowChangePw] = useState(false);
    const [facInfo, setFacInfo] = useState({});

    useEffect( () => {

        const matchingShop = shopList.find(shop => shop._id === myInfo.shopId);

        if (matchingShop) {
            setMyShop(matchingShop);
        }
    }, [shopList, myInfo])

    useEffect( () => {
        getMe();
        getMyShop();
    }, [editShop])

    const setShop = async () => {

        if (!myShop.name) {
            setEditShop(true);
        }
    }

    const changeShop = async () => {
        setEditShop(false);
    }

    useEffect(() => {
        getMyShop();
        getShopList();
        setShop();
        getMe();
    }, [])

    const getMe = () => {
        axios.get(`/api/member/getMe/${user._id}`).then((result) => {
            setMyInfo(result.data);
        }).catch()
    }
    const getMyShop = async () => {
        const shopId = user.shopId;
        await axios.get(`/api/shop/user/${shopId}`).then(res => {
            getFacInfo(res.data);
        })
    }
    const getFacInfo = (myShop) => {
        axios.get(`/api/fac/info/${myShop.facCode}`).then((res => {
            setFacInfo(res.data);
        }))
    }
    const logout = () => {
        axios.get('/api/member/logout').then(() => {
            navigate("/login");
        })
    }

    const getShopList = async () => {
        await axios.get('/api/admin/shop').then(response => {
            setShopList(response.data);
        })
    };

    const handleSelectChange = (event) => {
        const selectedOption = event.target;
        const optionValue = selectedOption.value; // 옵션의 value 속성
        const optionText = selectedOption.options[selectedOption.selectedIndex].textContent; // 옵션의 textContent
        setUpdateData({ ...updateData, shopId: optionValue, shopName: optionText });
    };

    const updateMember = async () => {

        const updateMember = { ...updateData, _id: user._id, };
        await axios.patch(`/api/member/${user._id}`, updateMember).then(
            result => {
                setUser(result.data);
                setEditShop(true);
            }
        ).catch();

    };

    return (
        <div className="">

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow'}>
                <h5 className={'text-center mt-2'}>내 정보</h5>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col-6 text-start">
                        <p className={'mb-1'}>{'이름 :' + myInfo.memName}</p>
                    </div>
                    <div className="col-6 text-start">
                        <p className={'mb-1'}>{'아이디 :' + myInfo.memId}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">연락처</label>
                        <p className={'mb-1'}>{myInfo.memTell}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">이메일</label>
                        <p className={'mb-1'}>{myInfo.email}</p>
                    </div>
                </div>
            </div>

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-ls'}>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className={'col justify-content-center d-flex align-items-center'}>
                        <h5>단골매장</h5>
                    </div>
                </div>
                {editShop ?
                    <div className={'row my-2 d-flex justify-content-center'}>
                        <div className={'col-6 justify-content-center d-flex align-items-center'}>
                            <span>{myShop.name}</span>
                        </div>
                        <div className={'col-6 justify-content-center d-flex align-items-center'}>
                            <button
                                onClick={changeShop}
                                className='btn btn-dark btn-sm rounded-4 mx-1'>변경하기</button>
                        </div>
                    </div>
                    :
                    <div className={'row my-2 d-flex justify-content-center'}>
                        <div className={'col-7 px-0 justify-content-center d-flex align-items-center'}>
                            <select className='form-select w-75' onChange={handleSelectChange} defaultValue={myShop.name}>
                                <option>선택</option>
                                {shopList.map((shop) => (
                                    <option key={shop._id} value={shop._id}>{shop.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className={'col-5 px-0 justify-content-center d-flex align-items-center'}>
                            <button
                                onClick={updateMember}
                                className='btn btn-dark btn-sm rounded-4 mx-1'>저장</button>
                        </div>
                    </div>
                }
            </div>
            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 shadow d-flex align-items-center shadow-ls'}>
                <h5 className={'text-center mt-2'}>매장 정보</h5>
                {!myShop ? <span>선택한 단골매장이 없습니다.</span> :
                <>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <p className={'mb-1'}>{'상호명 :' + myShop.name}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">연락처</label>
                        <p className={'mb-1'}>{myShop.tell}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">주소</label>
                        <p className={'mb-1'}>{myShop.address1 + myShop.address2}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">영업시간</label>
                        <p className={'mb-1'}>{myShop.openTime}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">휴무일</label>
                        <p className={'mb-1'}>{myShop.holiday}</p>
                    </div>
                </div>
                {myShop.tempInfo ?
                    <div className={'row my-1 d-flex justify-content-center'}>
                        <div className="col text-start">
                            <label className="form-label mb-0">임시휴무일</label>
                            <p className={'mb-1'}>{myShop.tempInfo}</p>
                        </div>
                    </div>
                    : null
                }
                </>
                }


            </div>
            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-ls'}>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className={'col'}>
                        <h5 className={'text-center mt-2'}>공방 입금 정보</h5>
                        <table className={'table'} style={{fontSize: '13px'}}>
                            <tr>
                                <td style={{width:'40%'}}>
                                    <span>은행</span>
                                </td>
                                <td>
                                    <span>{facInfo.accBK}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'30%'}}>
                                    <span>예금주</span>
                                </td>
                                <td>
                                    <span>{facInfo.accName}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width:'30%'}}>
                                    <span>계좌번호</span>
                                </td>
                                <td>
                                    <span style={{fontSize: '12px', padding: '0'}}>{facInfo.accNum}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <span style={{fontSize: '10px'}}>- 단골매장을 변경하시면 입금정보도 함께 변경됩니다.</span>
                </div>
            </div>

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-ls'}>
                <div className="col-8 d-grid my-2 text-start">
                    <button onClick={ () => { setShowChangePw(!showChangePw)}} className={'btn btn-sm btn-dark rounded-4'}>비밀번호 변경하기</button>
                </div>
                {showChangePw ?
                    <FindPw setShowChangePw={setShowChangePw} ></FindPw> : null
                }
            </div>
            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-ls'}>
                <div className="col-8 d-grid my-2 text-start">
                        <button className={'btn btn-dark btn-sm rounded-4'} onClick={logout}>로그아웃</button>
                </div>
            </div>
        </div>
    );
}

export default MyInfo;