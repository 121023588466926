import '../css/ItemModal.css';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import button from "bootstrap/js/src/button";

function ItemModal({mode, itemDetail, isOpen, onClose }) {

    const [modelImg, setModelImg] = useState(null);
    const [modelName, setModelName] = useState('');
    const [leafName, setLeafName] = useState('');
    const [tubeName, setTubeName] = useState('');
    const [caseName, setCaseName] = useState('');
    const [modelPrice, setModelPrice] = useState(0);
    const [factoryPrice, setFactoryPrice] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(mode === 'new' ? true : mode === 'edit' ? true : false);



    useEffect(() => {

        setModelName(itemDetail.modelName);
        setLeafName(itemDetail.leafName);
        setTubeName(itemDetail.tubeName);
        setCaseName(itemDetail.caseName);
        setModelPrice(itemDetail.modelPrice);
        setFactoryPrice(itemDetail.factoryPrice);

        if(mode === 'new'){
            setIsNew(true);
            setIsEdit(true);
        }
        else {
            setIsNew(false);
            setIsEdit(false);
        }

        if (itemDetail) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [itemDetail]);


    const setEdit = () => {
        setIsEdit(!isEdit);
    }

    const updateModelImg = (event) => {
        setModelImg(event.target.files[0]);
    };
    const updateModelName = (event) => {
        setModelName(event.target.value);
    };
    const updateLeafName = (event) => {
        setLeafName(event.target.value);
    };
    const updateTubeName = (event) => {
        setTubeName(event.target.value);
    };
    const updateCaseName = (event) => {
        setCaseName(event.target.value);
    };
    const updateModelPrice = (event) => {
        setModelPrice(event.target.value);
    };
    const updateFactoryPrice = (event) => {
        setFactoryPrice(event.target.value);
    };

    const submit = async () => {

        const data = new FormData();
        data.append('modelName', modelName);
        data.append('leafName', leafName);
        data.append('tubeName', tubeName);
        data.append('caseName', caseName);
        data.append('modelPrice', modelPrice);
        data.append('factoryPrice', factoryPrice);
        data.append('modelImg', modelImg);

        await axios.post('/api/admin/item', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(result => {
            alert('등록완료');
        }).catch(error => {
            alert('등록실패');
            
        });
    };

    const updateImg = async () => {
        if(modelImg){
            const data = new FormData();
            data.append('modelImg', modelImg);

            await axios.post(`/api/admin/item/img/${itemDetail._id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then().catch()
        }

    }

    const update = async () => {

        const data = {
            modelName,
            leafName,
            caseName,
            tubeName,
            modelPrice,
            factoryPrice,
        };

        await axios.patch(`/api/admin/item/${itemDetail._id}`, data)
            .then(result => {
                setModelName('');
                setLeafName('');
                setTubeName('');
                setCaseName('');
                setModelPrice('');
                setFactoryPrice('');
                setIsEdit(false);
                onClose();

            })
            .catch(error => {});
    };




    if (!isOpen) return null;

    return (
        loading ? <span>로딩중</span> :
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background">
                <div className="item-modal">
                    {isNew ?
                    <h3 className={'text-center'}>상품등록</h3>
                    : <h3 className={'text-center'}>상품상세정보</h3>
                    }
                    <div className={'row my-5 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label">모델명</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       value={ modelName }
                                       onChange={ updateModelName }
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{modelName}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label">담배잎</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       value={ leafName }
                                       onChange={ updateLeafName }
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{leafName}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label">튜브</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       value={ tubeName }
                                       onChange={ updateTubeName }
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{tubeName}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label">모델가격</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       value={ modelPrice }
                                       onChange={ updateModelPrice }
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{modelPrice}</h5>
                                    </>
                                }
                            </div>
                        </div> 
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label">공방가격</label>
                                {isEdit ?
                                    <>
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       value={ factoryPrice }
                                       onChange={ updateFactoryPrice }
                                />
                                    </>
                                    :
                                    <>
                                        <h5>{factoryPrice}</h5>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                {isEdit ?
                                    <>
                                <label className="form-label">상품이미지</label>
                                <input type="file"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       onChange={ updateModelImg }
                                />
                                    </>
                                    :
                                    <>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex mt-3 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">

                                {isNew ?
                                    <input type={'button'}  onClick={ () => { submit(); updateImg(); onClose(); }} value={'등록'} className={'btn btn-dark rounded-3'}/>
                                    :
                                    isEdit ?
                                        <button className={'btn btn-dark rounded-3'} onClick={ () => { update(); updateImg(); onClose(); }}>저장</button>
                                        :
                                        <button className={'btn btn-dark rounded-3'} onClick={setEdit}>수정</button>
                                }
                                <input onClick={ () => { onClose(); setIsEdit(false); }} type={'button'} className={'btn btn-dark rounded-3 mx-3'} value={'닫기'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemModal;
