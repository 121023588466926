import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import DaumPostcode from 'react-daum-postcode';
import button from "bootstrap/js/src/button";

function FactoryDetail({ isOpen, onClose, factoryDetail, mode }) {

    const [openPostcode, setOpenPostcode] = useState(false);
    const [factoryData, setFactoryData] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(mode === 'new' ? true : mode === 'edit' ? true : false);
    const [loading, setLoading] = useState(true);
    const [memberList, setMemberList] = useState([]);
    const [shopList, setShopList] = useState();
    const [svList, setSvList] = useState();
    const [preSv, setPreSv] = useState({});
    const selectedSV = useRef('');


    const getMember = async () => {

        const query = 'memRole=factory';
        await axios.get('/api/member/role?'+query).then(result => {
            setMemberList([...result.data]);
        })
    }
    const getShop = async () => {

        await axios.get('/api/admin/shop').then(result => {
            setShopList([...result.data]);
        })
    }
    const getSv = async () => {

        await axios.get('/api/admin/sv').then(result => {
            setSvList([...result.data]);
        })
    }
    const resetSv = async () => {

        const data = {
            _id: factoryData.sv,
            facCode: factoryData._id,
            facName: factoryData.name
        };

        await axios.patch(`/api/admin/sv/reset/${preSv}`, data).then(result => {
            setSvList([...result.data]);
        })
    }

    useEffect(() => {
        setFactoryData(factoryDetail);

        setPreSv(factoryDetail.sv);

        getSv()
        getShop();
        getMember();

        if(mode === 'new'){
            setIsNew(true);
            setIsEdit(true);
        }
        else {
            setIsNew(false);
            setIsEdit(false);
        }

        if (factoryData) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [factoryDetail]);


    const setEdit = () => {
        setIsEdit(!isEdit);
    }

    if (!isOpen) {
        return null;
    }

    const clickButton = () => {
        setOpenPostcode(current => !current);
    };

    const  selectAddress = (data) => {
        setFactoryData((prevFactory) => ({
            ...prevFactory,
            'address1': data.address, // name 속성을 키로 사용하여 값을 업데이트
        }));
        setOpenPostcode(false);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // 현재 상태 복사본을 만들고 업데이트
        setFactoryData((prevFactory) => ({
            ...prevFactory,
            [name]: value, // name 속성을 키로 사용하여 값을 업데이트
        }));
    };

    const addShop = () => {
        const selectedShopId = document.querySelector('.allShop').value;
        const selectedShop = shopList.find(shop => shop._id === selectedShopId);

        if (selectedShop) {
            setFactoryData((prevFactory) => {
                return {
                    ...prevFactory,
                    shop: [...prevFactory.shop, selectedShop._id],
                    shopName: [...prevFactory.shopName, selectedShop.name],
                };
            });
        }
    }

    const addSv = (e) => {
        const selectedSvId = e.target.value;
        const selectedSv = svList.find(sv => sv._id === selectedSvId);

        if (selectedSv) {
            setFactoryData((prevFactory) => {
                return {
                    ...prevFactory,
                    sv: selectedSv._id,
                    svName: selectedSv.name,
                };
            });
        }
    }

    const removeShop  = () => {
        // 선택된 select 박스의 값과 텍스트 가져오기
        const selectedValue = document.querySelector('.selectedShop').value;

        // factoryData에서 선택된 값과 텍스트를 찾아서 삭제
        setFactoryData((prevFactory) => {
            const shopIndex = prevFactory.shop.indexOf(selectedValue);

            if (shopIndex !== -1) {
                // 선택된 값이 shop 배열에 있으면 해당 항목 제거
                prevFactory.shop.splice(shopIndex, 1);
                prevFactory.shopName.splice(shopIndex, 1);
            }

            return {
                ...prevFactory,
            };
        });
    };


    const update = async () => {

        await axios.patch(`/api/admin/factory/${factoryData._id}`, factoryData).then(()=> {
            setFactoryData({});
            onClose();
            setIsEdit(false);
        }).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;
    }

    const updateSv = async () => {

        const data = {
            _id: factoryData.sv,
            facCode: factoryData._id,
            facName: factoryData.name
        };
        await axios.patch(`/api/admin/sv/${factoryData.sv}`, data).then(result => {

        }).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;

    }
    const updateShop = () => {

        const shopCode = factoryData.shop;

        shopCode.forEach(async (shop, idx) => {
            const data = {
                _id: shop,
                facCode: factoryData._id,
                facName: factoryData.name
            };
            await axios.patch(`/api/admin/shop/${shop}`, data).then(result => {
                alert('성공');

            }).catch(err => {
                if(err.response.status){
                    alert(err.response.data);
                }
                else {
                    alert('잠시 후 다시 시도해 주세요')
                }
            });;
        })

    }



    const submit = async () => {

        await axios.post('/api/admin/factory', factoryData).then(()=> {
            alert('등록완료')
            setFactoryData({});
            onClose();
            setIsEdit(false);
        }).catch(err => {
            alert('잠시 후 다시 시도해 주세요')
    })
    }



    if (!isOpen) return null;

    return (
    loading ? <span>로딩중</span> :
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background">
                <div className="item-modal">
                    {isNew ?
                    <h3 className={'text-center'}>공방등록</h3>
                    :<h3 className={'text-center'}>공방상세정보</h3>
                    }
                    <div className={'row my-5 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                            <span>공방명</span>
                                {isEdit ?
                                    <>
                                        <input type="text"
                                               className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                               name={'name'}
                                               value={factoryData.name}
                                               onChange={handleInputChange}
                                        />
                                    </>
                                :
                                    <>
                                        <h5>{factoryData.name}</h5>
                                    </>
                                }
                            </div>
                            <div className="col-5">
                                <span>점주명</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       name={'owner'}
                                       value={factoryData.owner}
                                       onChange={handleInputChange}
                                />
                                :
                                    <h5>{factoryData.owner}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>연락처</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'tell'}
                                       value={factoryData.tell}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{factoryData.tell}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-8">
                                <span>주소</span>
                                {isEdit ?

                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'address1'}
                                       value={factoryData.address1}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{factoryData.address1}</h5>

                                }
                            </div>
                            <div className={'col-2 d-flex pt-4'}>
                                {isEdit ?
                                <button className={'btn btn-sm btn-dark rounded-3'} onClick={clickButton}>검색</button>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-10">
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'address2'}
                                       value={factoryData.address2}
                                       onChange={handleInputChange}
                                       placeholder={'상세주소입력'}
                                />
                                    :
                                    <h5>{factoryData.address2}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <span>은행</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accBK'}
                                       value={factoryData.accBK}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{factoryData.accBK}</h5>
                                }
                            </div>
                            <div className="col-5">
                                <span>예금주</span>
                                {isEdit ?
                                    <input type="text"
                                           className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                           id=""
                                           name={'accName'}
                                           value={factoryData.accName}
                                           onChange={handleInputChange}
                                    />
                                    :
                                    <h5>{factoryData.accName}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>계좌번호</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accNum'}
                                       value={factoryData.accNum}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{factoryData.accNum}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <span>회원ID</span>
                                {
                                    isEdit ?
                                        (
                                            <select value={factoryData.memCode} name={'memId'} onChange={handleInputChange} className={'form-select'}>
                                                    <option value={''}></option>
                                                {memberList.map((member) => (
                                                    <option value={member.memId}>{member.memId}</option>
                                                ))}
                                            </select>
                                        )
                                        :
                                        <h5>{factoryData.memId}</h5>
                                }
                            </div>                            
                            <div className="col-5">
                                <span>슈퍼바이저</span>
                                {
                                    isEdit ?
                                        (
                                            <select defaultValue={factoryData.sv} name={'sv'} ref={selectedSV} onChange={addSv} className={'form-select selectedSv'}>
                                                <option value={''}></option>
                                                {svList.map((sv) => (
                                                    <option value={sv._id}>{sv.name}</option>
                                                ))}
                                            </select>
                                        )
                                        :
                                        <h5>{factoryData.svName}</h5>
                                }
                            </div>
                        </div>                        
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                {!isNew && isEdit ?
                                    <>
                                        <span>상점</span>
                                        <select className={'form-select mb-2 selectedShop'}>
                                            {factoryData.shopName.map((shop, idx) => (
                                                <option value={factoryData.shop[idx]}>{shop}</option>
                                            ))}
                                        </select>
                                        {isNew ?
                                            <>
                                            </>
                                            :
                                            <button className={'btn btn-sm btn-dark'} onClick={removeShop}>삭제</button>
                                        }
                                    </>
                                    :
                                    <>
                                        {/*<span>상점목록</span>*/}
                                        {/*{factoryData.shopName.map((shop, idx) => (*/}
                                        {/*    <h5>{shop}</h5>*/}
                                        {/*))}*/}
                                    </>
                                }
                            </div>
                            <div className="col-5">
                                {isEdit && !isNew ?
                                    <>
                                        <span>상점</span>
                                        <select className={'form-select mb-2 allShop'}>
                                                <option value={''}></option>
                                            {shopList.map((shop) => (
                                                <option value={shop._id}>{shop.name}</option>
                                            ))}
                                        </select>
                                        {isNew ?
                                            <></>
                                            :
                                            <button className={'btn btn-sm btn-dark'} onClick={addShop}>추가</button>
                                        }

                                    </>
                                    : <>
                                        {/*<span>상점목록</span>*/}
                                        {/*{factoryData.shopName.map((shop, idx) => (*/}
                                        {/*    <h5>{shop}</h5>*/}
                                        {/*))}*/}
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row d-flex mt-3 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                            {isNew ?
                                <input type={'button'} onClick={submit} value={'등록'} className={'btn btn-dark rounded-3'}/>

                                :
                                    isEdit ?
                                        <input type={'button'} onClick={ () => {
                                            update();
                                            resetSv();
                                            updateSv();
                                            updateShop();
                                        }}
                                               value={'저장'}
                                               className={'btn btn-dark rounded-3'}/>

                                        :
                                        <input type={'button'}
                                               onClick={ setEdit }
                                               value={'수정'}
                                               className={'btn btn-dark rounded-3'}/>
                            }
                                <input onClick={ () => { onClose(); setIsEdit(false); }} type={'button'} className={'btn btn-dark rounded-3 mx-3'} value={'닫기'}/>
                            </div>

                        </div>
                    </div>
                 </div>
            </div>

            {openPostcode &&
                <div className={'item-modal-overlay'}>
                    <div className={'item-modal-background'}>
                        <div className={'item-modal'}>
                            <button type="button" className="btn-close shadow-none" onClick={clickButton} aria-label="Close"></button>
                            <DaumPostcode
                                onComplete={selectAddress}  // 값을 선택할 경우 실행되는 이벤트
                                autoClose={false} // 값을 선택할 경우 사용되는 DOM을 제거하여 자동 닫힘 설정
                            />
                        </div>
                    </div>
                </div>
            }
        </div>


    );
}

export default FactoryDetail;