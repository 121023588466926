import React, {useState, useEffect} from "react";
import FactoryModal from "./FactoryModal.js";
import FactoryDetail from "./FactoryDetail";
import axios from "axios";
import {Await} from "react-router-dom";

function Factory() {

    const newData = {
        "name": "",
        "address1": "",
        "address2": "",
        "tell": "",
        "owner": "",
        "shop": [],
        "accName": "",
        "accNum": "",
        "accBK": "",
    }

    const [deTailModal, setDeTailModal] = useState(false);
    const [factory, setFactory] = useState([]);
    const [mode, setMode] = useState();
    const [factoryDetail, setFactoryDetail] = useState({});
    const [originList, setOriginList] = useState([]);
    const [keyword, setKeyword] = useState('');



    const openDetail = (data) => {
        setDeTailModal(true);
    }

    const closeDetail = () => {
        setDeTailModal(false);
        setFactoryDetail({});
    }

    useEffect(() => { getFacotyList() }, []);
    useEffect(() => { getFacotyList() }, [deTailModal]);

    const openRegModal = () => {
        setMode('new');
        setFactoryDetail({...newData});
        openDetail();
    };

    const kw = (event) => {
        setKeyword(event.target.value);
    }
    const search = async () => {
        const cate = document.querySelector('.searchCate').value;

        const result = originList.filter(order => order[cate].includes(keyword));
        setFactory(result);

    }

    const getFactoryDetail = async (factoryId) => {
        setMode('view');
        await setFactoryDetail(factoryId);
        openDetail();
    }

    const getFacotyList = async () => {
        await axios.get('/api/admin/factory')
            .then(result => {
                setFactory(result.data);
                setOriginList(result.data);
                }
            )
            .catch(error => {

            })
    };

    const updateIsUse = async (event, fac) => {
        const update = { _id: fac._id, isUse: !fac.isUse };
        await axios.patch(`/api/admin/factory/${fac._id}`, update).then(
            result => {
                getFacotyList();

            }
        ).catch();

    };

    return (
        <div className="mx-2">
            {/* 컨트롤영역 */}
            <div className={'row d-flex justify-content-center my-3'}>
                <div className={'col-4 d-flex justify-content-start px-0'}>
                    <button className={'btn btn-sm btn-dark rounded-3 mx-2 border-3 rounded-4'}  onClick={
                        openRegModal} >공방등록</button>
                </div>
                <div className={'col-6 d-flex justify-content-end px-0'}>
                    <select className={'form-select-sm border-3 border-black rounded-4 searchCate'} defaultValue={'name'}>
                        <option value={'name'}>공방명</option>
                        <option value={'owner'}>점장명</option>
                        <option value={'memId'}>ID</option>
                    </select>
                    <input type={'text'}
                           placeholder={'검색어를 입력하세요'}
                           style={{width : "200px"}}
                           className={'d-inline-flex mx-1 focus-ring focus-ring-dark py-1 px-2 text-decoration-none border-3 border-black rounded-4 form-control'}
                           onChange={ kw }

                    />
                    <button className={'btn btn-dark btn-sm rounded-3 mx-1 border-3 rounded-4'} onClick={ search } >검색</button>
                    <button className={'btn btn-dark btn-sm rounded-3 mx-1 border-3 rounded-4'} onClick={getFacotyList} >전체보기</button>
                </div>

            </div>
            {/*상품목록영역*/}
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>NO</td>
                            <td>공방명</td>
                            <td>관리자</td>
                            <td>관리자ID</td>
                            {/*<td>사용여부</td>*/}
                            <td>상세정보</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            factory.map((fac, idx) => (
                                <tr key={fac._id}>
                                    <td>{idx + 1}</td>
                                    <td>
                                        <span>
                                            {fac.name}
                                        </span>
                                    </td>
                                    <td>{fac.owner}</td>
                                    <td>
                                        {fac.memId}
                                    </td>
                                    {/*<td>*/}
                                    {/*    <div className="form-check form-switch justify-content-center align-items-center d-flex">*/}
                                    {/*        <input className="form-check-input d-inline-flex focus-ring focus-ring-dark"*/}
                                    {/*               type="checkbox"*/}
                                    {/*               role="switch"*/}
                                    {/*               checked={fac.isUse}*/}
                                    {/*               onChange={ (event) => { updateIsUse(event, fac) }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td>
                                        <button className={'btn btn-sm btn-dark rounded-3 mx-2 border-3 rounded-4'}
                                        onClick={ () => {
                                            getFactoryDetail(fac);
                                        }}
                                        >상세</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            <FactoryDetail isOpen={deTailModal} onClose={closeDetail} factoryDetail={factoryDetail} mode={mode}/>
        </div>
    </div>
    );
}

export default Factory;
