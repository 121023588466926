import React from "react";
import AdminHeader from './AdminHeader'
import { Routes, Route, Outlet } from "react-router-dom";

function Admin() {

    return (
        <div className={'container-fluid mx-0'}>
            <AdminHeader/>
            <Outlet></Outlet>
        </div>
    );
}

export default Admin;