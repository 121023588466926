import React from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import {Link} from "react-router-dom";

function MenuBar() {
    return (
        <div className="bg-white row text-black d-flex justify-content-center mainFont" style={{height: '75px'}}>
            <div className="col-3 d-flex align-items-center justify-content-center">
                <Link to={'/user/userHome'} className="dropdown-item text-center">홈</Link>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-center">
                <Link to={'/user/item'} className="dropdown-item text-center">상품목록</Link>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-center">
                <Link to={'/user/orderList'} className="dropdown-item text-center">주문내역</Link>
            </div>
            <div className="col-3 d-flex align-items-center justify-content-center">
                <Link to={'/user/myInfo'} className="dropdown-item text-center">내정보</Link>
            </div>
        </div>
    );
}
export default MenuBar;
