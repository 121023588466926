import React, {useEffect, useState} from "react";
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";

function OrderedModal({selectOrder, modalClose}) {

    const [facInfo, setFacInfo] = useState({});

    const payInfoText = '공방 수공비용만 결제됩니다.\n' +
        '재료비용 ' + selectOrder.modelTotalPrice.toLocaleString() +'원은 단골매장에서 \n' +
        '현장결제해주세요.'


    useEffect(() => {
        getFacInfo();
    }, []);

    const getFacInfo = () => {
        axios.get(`/api/fac/info/${selectOrder.factoryId}`).then((res => {
            setFacInfo(res.data);
        }))
    }
    return (
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background">
                <div className="item-modal" style={{zIndex: '999'}}>
                    <h4 className={'text-center'}>주문 확인</h4>
                    <div className={'row my-1'}>
                        {selectOrder.model.map((item, index) => (
                            <>
                                <span className={'text-center my-1'} key={index}>{item.modelName} : {item.cnt} 개<br /></span>
                            </>
                        ))}
                    </div>

                    <div className={'row my-2 text-center'}>
                        <h5>입금 계좌 안내</h5>
                        <div className={'col'}>
                            <table className={'table'} style={{fontSize: '13px'}}>
                                <tr>
                                    <td style={{width:'40%'}}>
                                        <span>은행</span>
                                    </td>
                                    <td>
                                        <span>{facInfo.accBK}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'30%'}}>
                                        <span>예금주</span>
                                    </td>
                                    <td>
                                        <span>{facInfo.accName}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'30%'}}>
                                        <span>계좌번호</span>
                                    </td>
                                    <td>
                                        <span style={{fontSize: '12px', padding: '0'}}>{facInfo.accNum}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{width:'30%'}}>
                                        <span>입금금액</span>
                                    </td>
                                    <td>
                                        <span>
                                            {selectOrder.factoryTotalPrice.toLocaleString() + ' 원'}
                                        </span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className={'row my-2 text-center'}>
                        <span>
                            재료가격 : {selectOrder.modelTotalPrice.toLocaleString() + ' 원'}
                        </span>
                    </div>
                    <div className={'row d-flex justify-content-center my-2'}>
                        <div className={'col-12 d-flex justify-content-center'}>
                            <button className={'btn btn-sm btn-dark rounded-4 mx-2'} onClick={modalClose}>닫기</button>
                        </div>
                    </div>
                    <div className={'row text-start'}>
                        <span style={{fontSize: '14px', textAlign: 'start', whiteSpace: 'pre-wrap'}}>{payInfoText}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderedModal;