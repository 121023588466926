import React, {useEffect, useState} from "react";
import axios from "axios";
import {useRecoilState} from "recoil";
import {userState} from "../recoilState";

function Factory() {

    const [user, setUser] = useRecoilState(userState);
    const [myFac, setMyFac] = useState({});
    const [shopList, setShopList] = useState([]);

    useEffect(() => { getMyFac();  }, []);

    const getMyFac = async () => {
        await axios.get(`/api/fac/${user._id}`).then(response => {
            // API 요청이 성공한 경우
            setMyFac(response.data);
            getShopList(response.data.shop);
        })
    }

    const getShopList = async (shopLists) => {
        const newShopList = [];

        await Promise.all(shopLists.map(async (s) => {
            try {
                const response = await axios.get(`/api/shop/fac/${s}`);
                const result = response.data;
                newShopList.push(result);
            } catch (error) {
            }
        }));

        // 기존의 shopList에 새로운 데이터를 추가하여 업데이트
        setShopList([...shopList, ...newShopList]);
    }

    return (
        <div className="">
            <div className={'row d-flex justify-content-center'}>
                <div className={'col-10 px-0'}>
                    <table className={'table table-striped text-center'}>
                        <thead>
                        <tr>
                            <td>NO</td>
                            <td>상점명</td>
                            <td>점장명</td>
                            <td>연락처</td>
                        </tr>
                        </thead>
                        <tbody>
                        {shopList.map((shop, idx) => (
                            <tr>
                                <td>{shopList.length - idx}</td>
                                <td>{shop.name}</td>
                                <td>{shop.owner}</td>
                                <td>{shop.tell}</td>
                            </tr>
                        ))

                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Factory;