import React, {useEffect, useState} from 'react';
import axios from 'axios';

function SvModal({ isOpen, onClose, mode, svDetail }) {

    const [svInfo, setSvInfo] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isNew, setIsNew] = useState(mode === 'new' ? true : mode === 'edit' ? true : false);
    const [loading, setLoading] = useState(true);
    const [memberList, setMemberList] = useState([]);


    const getMember = async () => {

        const query = 'memRole=sv';
        await axios.get('/api/member/role?'+query).then(result => {
            setMemberList([...result.data]);
        })
    }

    useEffect(() => {

        setSvInfo(svDetail);

        getMember();

        if(mode === 'new'){
            setIsNew(true);
            setIsEdit(true);
        }
        else {
            setIsNew(false);
            setIsEdit(false);
        }

        if (svInfo) {
            setLoading(false);
        } else {
            setLoading(true);
        }

    }, [svDetail])


    const setEdit = () => {
        setIsEdit(!isEdit);
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        // 현재 상태 복사본을 만들고 업데이트
        setSvInfo((prevFactory) => ({
            ...prevFactory,
            [name]: value, // name 속성을 키로 사용하여 값을 업데이트
        }));

    };

    const submit = async () => {
        await axios.post('/api/admin/sv', svInfo).then(() => {

                setSvInfo({});
                onClose();
            }
        ).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;

    }

    const update = async () => {

        await axios.patch(`/api/admin/sv/${svInfo._id}`, svInfo).then(()=> {
            setSvInfo({});
            onClose();
            setIsEdit(false);
        }).catch(err => {
            if(err.response.status){
                alert(err.response.data);
            }
            else {
                alert('잠시 후 다시 시도해 주세요')
            }
        });;
    }


    if (!isOpen) return null;

    return (
        loading ? <span>로딩중</span> :
        <div className={'item-modal-overlay'}>
            <div className="item-modal-background">
                <div className="item-modal">
                    {isNew ?
                        <h3 className={'text-center'}>슈퍼바이저등록</h3>
                        :<h3 className={'text-center'}>슈퍼바이저 상세정보</h3>
                    }
                    <div className={'row my-5 d-flex justify-content-center'}>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>슈퍼바이저명</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       name={'name'}
                                       value={svInfo.name}
                                       onChange={handleInputChange}
                                /> :
                                    <h5>{svInfo.name}</h5>
                                }
                           </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>연락처</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'tell'}
                                       value={svInfo.tell}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{svInfo.tell}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-5">
                                <span>은행</span>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accBK'}
                                       value={svInfo.accBK}
                                       onChange={handleInputChange}
                                />
                                :
                                <h5>{svInfo.accBK}</h5>
                                }
                            </div>
                            <div className="col-5">
                                <label className="form-label mb-0">예금주</label>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accName'}
                                       value={svInfo.accName}
                                       onChange={handleInputChange}
                                />
                                       :
                                    <h5>{svInfo.accName}</h5>
                            }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <label className="form-label mb-0">계좌번호</label>
                                {isEdit ?
                                <input type="text"
                                       className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                                       id=""
                                       name={'accNum'}
                                       value={svInfo.accNum}
                                       onChange={handleInputChange}
                                />
                                    :
                                    <h5>{svInfo.accNum}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>회원ID</span>
                                {
                                    isEdit ?
                                        (
                                            <select value={svInfo.memId} name={'memId'} onChange={handleInputChange} className={'form-select'}>
                                                <option value={''}></option>
                                                {memberList.map((member) => (
                                                    <option value={member.memId}>{member.memId}</option>
                                                ))}
                                            </select>
                                        )
                                        :
                                        <h5>{svInfo.memId}</h5>
                                }
                            </div>
                        </div>
                        <div className="row d-flex my-1 justify-content-center">
                            <div className="col-10">
                                <span>관리공방</span>
                                <h5>{svInfo.facName}</h5>
                            </div>
                        </div>

                        <div className="row d-flex mt-3 justify-content-center">
                            <div className="col-10 d-flex justify-content-center">
                                {isNew ?
                                    <input type={'button'} onClick={submit} value={'등록'} className={'btn btn-dark rounded-3'}/>
                                    :
                                    isEdit ?
                                        <input type={'button'} onClick={ () => {
                                            update();
                                        }}
                                               value={'저장'}
                                               className={'btn btn-dark rounded-3'}/>

                                        :
                                        <input type={'button'}
                                               onClick={ setEdit }
                                               value={'수정'}
                                               className={'btn btn-dark rounded-3'}/>
                                }
                                <input onClick={ () => { onClose(); setIsEdit(false); }} type={'button'} className={'btn btn-dark rounded-3 mx-3'} value={'닫기'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );

}

export default SvModal;