import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

function Download ({setIsPWA}){

    const navigate = useNavigate();
    const [isIOS, setIsIOS] = useState(false);

    let deferredPrompt
    window.addEventListener('beforeinstallprompt', event => {
        event.preventDefault()
        deferredPrompt = event
    })

    useEffect(() => {
        // 운영 체제 정보에서 iOS 검사
        const platform = window.navigator.platform;
        if (platform === 'iPhone' || platform === 'iPad') {
            setIsIOS(true);
        }
    }, []);

    const installApp = () => {
        if (!deferredPrompt) {
            return
        }
        deferredPrompt.prompt()
    }

    const skip = () => {
        setIsPWA(true);
        navigate("/");
    }

    const downloadFile = async () => {
        try {
            const response = await axios({
                url: '/api/admin/down',
                method: 'GET',
                responseType: 'blob', // 데이터 타입을 blob으로 설정
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'damho.apk');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('파일 다운로드 중 오류가 발생했습니다:', error);
        }
    };

    return (
        <div className={'container'}>
            <span onClick={skip} style={{fontSize: '9px', color: 'gray'}}>웹페이지 버전으로 계속하기</span>
            <div className={'row d-flex justify-content-center my-5 mt-5 mainFont'}>
                <div className={'col-9'}>
                    <div className={'row mt-5 my-5 mb-5 d-flex justify-content-center'}>
                        <div className={'col d-flex justify-content-center'}>
                            <img src={'../../../../uploads/img/1.png'} className={'loginImg'}/>
                        </div>
                    </div>
                    {isIOS ?
                    <div className={'row my-2 text-center'}>
                        <h5>아이폰 설치방법</h5>
                        <div className={'col'}>
                            <p className={'my-1'} style={{whiteSpace: 'pre-wrap'}}>
                                사파리 하단 메뉴의
                            </p>
                            <p className={'my-1'}>
                                <img style={{width: '20px'}} src={'../../../../uploads/img/share.png'} className={'loginImg me-1'}/>
                                공유버튼
                            </p>
                            <p className={'my-1'}>
                                터치 후 홈 화면에 추가
                            </p>
                        </div>
                    </div>
                        :
                        <>
                            <div className={'row my-2 d-flex justify-content-center'}>
                                <div className={'col-md-6 d-grid'}>
                                    <button onClick={downloadFile}>앱 다운로드</button>
                                </div>
                            </div>
                            <div className={'row my-5 d-flex justify-content-center'}>
                                <div className={'col-md-6 d-grid'}>
                                    <button onClick={installApp}>기존 버전설치</button>
                                </div>
                             </div>
                        </>
                    }
                </div>
            </div>
        </div>

    )
}


export default Download;