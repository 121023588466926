import React, { useState, useEffect } from 'react';
import 'bootstrap-icons/font/bootstrap-icons.css';
import axios from "axios";
import { useRecoilState } from 'recoil';
import { userState } from "../recoilState"
import OrderedModal from "./OrderedModal";

function UserHome() {

    const initData = {
        model : []
    }
    const orderInfo = '  본 어플을 이용하여 원하시는 모델을 선택 제작의뢰하시면 공방에서 제작하여 의뢰 다음날 지정된단골매장에서 수령 하실 수 있습니다. \n' +
        ' (D+1일/ 주말 및 공휴일 제외)\n' +
        '\n' +
        ' 본 어플에서는 제작에 들어가는 공임만 결제하며 재료비는 단골매장에서 수령시에 결제하시면 됩니다.\n' +
        '\n' +
        ' 단골매장에서 보관하는 제작물은 단골매장 도착 후 10일이 경과하면 폐기합니다. 이점 반드시 숙지 부탁드립니다.'

    const [user, setUser] = useRecoilState(userState);
    const [lastOrder, setLastOrder] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [myShop, setMyShop] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectOrder, setSelectOrder] = useState({});

    useEffect(() => {
        setTotalPrice(parseInt(lastOrder.modelTotalPrice + lastOrder.factoryTotalPrice));
    }, [lastOrder])

    useEffect(() => {
        getLastOrder();
        getMyShop();
    }, [])

    const modalClose = () => {
        setIsModalOpen(false);
    }

    const modalOpen = (order) => {
        setSelectOrder(order);
        setIsModalOpen(true);
    }

    const getLastOrder = async () => {
        const memId = user._id;
        await axios.get(`/api/order/lastOrder/${memId}`).then(res => {
            setLastOrder(res.data);
        })
    }

    const getMyShop = async () => {
        const shopId = user.shopId;
        await axios.get(`/api/shop/user/${shopId}`).then(res => {
            setMyShop(res.data);
        })
    }

    return (
        <div className="" style={{whiteSpace: 'pre-wrap'}}>

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-lg'}>
                <h5 className={'text-center mt-2'}>이용 안내</h5>
                <div className="col text-start">
                    <p>{orderInfo}</p>
                </div>
            </div>

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-lg'}>
                <div className={'row my-1 d-flex justify-content-center'}
                     onClick={ () => {
                         modalOpen(lastOrder);
                     } }
                >
                    <span className={'text-start'}>최근 주문</span>
                    <div className={'col'}>
                        <h5 className={'text-center'}>{lastOrder.orderState === '공방발송' || lastOrder.orderState === '제작중' || lastOrder.orderState === '제작완료' ? '제작중' : lastOrder.orderState}</h5>
                        {lastOrder.length === 0 ? <span>주문 내역이 없습니다.</span> :
                            lastOrder.model.map(o => (
                            <div className={'row my-1 d-flex justify-content-center'} key={o.modelId}>
                                <div className={'col justify-content-center d-flex align-items-center'}>
                                    <span key={o.modelId}>{o.modelName} : {o.cnt}개</span>
                                </div>
                            </div>
                        ))

                        }

                        {lastOrder.length !== 0 &&
                            <>
                                <div className={'row my-1 d-flex justify-content-center'}>
                                    <div className={'col justify-content-center d-flex align-items-center'}>
                                        <span>총 결제금액 : {totalPrice.toLocaleString() + ' 원'}</span>
                                    </div>
                                </div>
                                <div className={'row my-1 d-flex justify-content-center'}>
                                    <div className={'col justify-content-center d-flex align-items-center'}>
                                        <span style={{fontSize: '12px'}}>주문일시 : {lastOrder.orderDate}</span>
                                    </div>
                                </div>
                            </>
                    }
                    </div>
                </div>
            </div>

            <div className={'row border-3 border border-dark rounded-4 mx-1 my-3 d-flex justify-content-center shadow-lg'}>
                <h5 className={'text-center mt-2'}>단골 매장 정보</h5>
                {!myShop ? <span>선택된 단골 매장이 없습니다.</span> :
                    <>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <p className={'mb-1'}>{'상호명 :' + myShop.name}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">연락처</label>
                        <p className={'mb-1'}>{myShop.tell}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">주소</label>
                        <p className={'mb-1'}>{myShop.address1 + myShop.address2}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">영업시간</label>
                        <p className={'mb-1'}>{myShop.openTime}</p>
                    </div>
                </div>
                <div className={'row my-1 d-flex justify-content-center'}>
                    <div className="col text-start">
                        <label className="form-label mb-0">휴무일</label>
                        <p className={'mb-1'}>{myShop.holiday}</p>
                    </div>
                </div>
                {myShop.tempInfo ?
                    <div className={'row my-1 d-flex justify-content-center'}>
                        <div className="col text-start">
                            <label className="form-label mb-0">임시휴무일</label>
                            <p className={'mb-1'}>{myShop.tempInfo}</p>
                        </div>
                    </div>
                    : null
                }
                </>
                }

            </div>

            {isModalOpen &&
                <OrderedModal selectOrder={selectOrder} modalClose={modalClose}></OrderedModal>
            }
        </div>

    );

}

export default UserHome;