import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import button from "bootstrap/js/src/button";
import { useRecoilState } from 'recoil';
import { userState } from "./recoilState";
import {resultingClientExists} from "workbox-core/_private";
function Join() {

    const navigate = useNavigate();

    const [user, setUser] = useRecoilState(userState);

    const [member, setMember] = useState({
        memId: '',
        memPw: '',
        memPwChk: '',
        memName: '',
        email: '',
        memTell: '',
        memAddr: '',
        memBirthday: '',
    });

    const [idCheck, setIdCheck] = useState(false);
    const [idCheckStart, setIdCheckStart] = useState(false);
    const [idDuple, setIdDuple] = useState(false);
    const [emailCheck, setEmailCheck] = useState(false);
    const [emailCheckStart, setEmailCheckStart] = useState(false);
    const [emailDuple, setEmailDuple] = useState(false);
    const [pwCheck, setPwCheck] = useState(false);
    const [pwInput, setPwInput] = useState(false);
    const [pwDupleCheck, setPwDupleCheck] = useState(false);
    const [nameCheck, setNameCheck] = useState(false);
    const [nameInput, setNameInput] = useState(false);
    const [tellCheck, setTellCheck] = useState(false);
    const [tellInput, setTellInput] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    useEffect( () => {
        pwDupleChk();
    }, [member.memPwChk]);

    useEffect( () => {
        idChk();
        setIdDuple(true);
        setIdCheckStart(false);
    }, [member.memId]);

    useEffect( () => {
        emailChk();
        setEmailDuple(true);
        setEmailCheckStart(false);

    }, [member.email]);

    useEffect( () => {
        pwChk();
        pwDupleChk();
        setPwInput(true);
    }, [member.memPw]);

    useEffect( () => {
        nameChk();
        setNameInput(true);
    }, [member.memName]);

    useEffect( () => {
        tellChk();
        setTellInput(true);
    }, [member.memTell]);

    const tellNullCheck = () => {
         const second = document.querySelector('#tell2').value;
        const third = document.querySelector('#tell3').value;
        return second === '' || third === '';
    }

    const makeTell = (event) => {

        const target = event.target.id;
        let first = document.querySelector('#tell1').value;
        let second = document.querySelector('#tell2').value;
        let third = document.querySelector('#tell3').value;
        let result;

        if(event.target.value.length > 4){
            event.target.value = '';
            return
        }

        if(target === 'tell1') {
            first = event.target.value;
        }
        if(target === 'tell2'){
            second = event.target.value;
        }
        if(target === 'tell3'){
            third = event.target.value;
        }

        result = first + '-' + second + '-' + third;
        member.memTell = result;
        console.log(member.memTell);
    }


    const check = () => {

        if(!member.memId){
            alert('아이디가 올바르지 않습니다.')
            return true
        }
        if(idDuple){
            alert('아이디 중복확인을 먼저 해주세요')
            return true
        }
        if(!member.email){
            alert('이메일이 올바르지 않습니다.')
            return true
        }
        if(emailDuple){
            alert('이메일 중복확인을 먼저 해주세요')
            return true
        }
        if(!member.memPw){
            alert('비밀번호가 올바르지 않습니다.')
            return true
        }
        if(!member.memPwChk){
            alert('비밀번호가 올바르지 않습니다.')
            return true
        }
        if(!pwDupleCheck){
            alert('비밀번호가 올바르지 않습니다.')
            return true
        }
        if(!member.memName) {
            alert('이름을 입력하세요');
            return true
        }
        if(!member.memTell){
            alert('연락처를 입력하세요');
            return true
        }
        if(tellNullCheck()){
            alert('연락처를 입력하세요');
            return true
        }
        return false
    }


    const idChk = () => {
        const regex = /^(?=.*[a-zA-Z])([a-zA-Z0-9]{4,})$/;
        regex.test(member.memId) ? setIdCheck(true) : setIdCheck(false);
    }

    const emailChk = () => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        regex.test(member.email) ? setEmailCheck(true) : setEmailCheck(false);

    }

    const pwChk = () => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        regex.test(member.memPw) ? setPwCheck(true) : setPwCheck(false);
    }

    const nameChk = () => {
        const regex = /^[가-힣]{2,4}$/;
        regex.test(member.memName) ? setNameCheck(true) : setNameCheck(false);

    }

    const tellChk = () => {
        const regex = /^010\d{8}$/;
        regex.test(member.memTell) ? setTellCheck(true) : setTellCheck(false);
    }
    const checkDupleId = (key) => {

        let val = '';
        key === 'memId' ? val = member.memId : val = member.email;
        axios.get(`/api/member/checkDupleId?${key}=${val}`)
            .then(result => {
                key === 'memId' ? setIdDuple(false) : setEmailDuple(false);
                key === 'memId' ? setIdCheckStart(true) : setEmailCheckStart(true);
            })
            .catch(error => {
                key === 'memId' ? setIdDuple(true) : setEmailDuple(true);
                key === 'memId' ? setIdCheckStart(true) : setEmailCheckStart(true);
            });
    };

    const updateMemInfo = (event) => {
        const key = event.target.name;
        const value = event.target.value;
        setMember(prevMember => ({
            ...prevMember,
            [key]: value
        }));
    }

    const pwDupleChk = () => {
        member.memPw === member.memPwChk ? setPwDupleCheck(true) : setPwDupleCheck(false)
    }

    const join = async () => {

        if(check()){
            return;
        }
        await axios.post('/api/member/join', member).then(async result => {
            await setUser(result.data.user);
            navigate('/');
        }).catch(e => {
            alert('회원가입 실패');
        });
    };

    return (
        <div className={'row mx-2 mb-5 d-flex justify-content-center mainFont'}>
            <div className={'col-10'}>
                <div className={'row mt-5 my-5 mb-3 d-flex justify-content-center'}>
                    <div className={'col d-flex justify-content-center'}>
                        <img src={'../../../../uploads/img/4.png'} className={'joinImg'}/>
                    </div>
                </div>
                <h1 className={'text-center mb-5'}>회원가입</h1>
                <div className={'row'}>
                    <div className="col-8 my-2">
                        <label className="form-label mb-0">아이디</label>
                        <input type="text"
                            className={'d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control'}
                            value={member.memId}
                            onChange={updateMemInfo}
                            name={'memId'}
                        />
                    </div>
                    <div className={'col-2 d-flex mt-3 mb-0 align-items-center'}>
                        <input disabled={!idCheck} type={'button'} onClick={ ()=> { checkDupleId('memId') }} className={'btn btn-sm btn-dark rounded-3 mx-3'} value={'중복확인'} />
                    </div>

                    {/*//정규식 && 중복확인 ? 사용가능 : 정규식 ? 정규식문구 : 중복확인 ? 중복확인문구 : null*/}
                    {idCheck && !idDuple ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>사용가능한 ID입니다.</span> :
                        !idCheck ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>4자리 이상 영문, 숫자만 가능합니다.</span> :
                            idCheckStart && idDuple ? <span className={'mt-0 mb-3'} style={{fontSize: '10px', color: 'red'}}>사용중인 ID입니다.</span> :
                                !idCheckStart ? <span className={'mt-0 mb-3'} style={{fontSize: '10px', color: 'red'}}>ID중복검사를 해주세요</span> : null
                    }
                </div>
                <div className={'row'}>
                    <div className="col-8 my-2">
                        <label className="form-label mb-0">이메일</label>
                        <input type="text"
                               className={'d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control' + (emailCheckStart && emailDuple ? ' border-danger' : '')}
                               name={'email'}
                               onChange={updateMemInfo}
                               value={member.email}
                               required={true}
                               placeholder={''}
                        />
                    </div>
                    <div className={'col-2 d-flex mt-3 align-items-center'}>
                        <input disabled={!emailCheck} type={'button'} onClick={()=> { checkDupleId('email') }} className={'btn btn-sm btn-dark rounded-3 mx-3'} value={'중복확인'} />
                    </div>
                    {emailCheck && !emailDuple ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>사용가능한 이메일입니다.</span> :
                        !emailCheck ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>이메일형식에 맞춰 입력하세요</span> :
                            emailCheckStart && emailDuple ? <span className={'mt-0 mb-3'} style={{fontSize: '10px', color: 'red'}}>사용중인 이메일입니다.</span> :
                                !emailCheckStart ? <span className={'mt-0 mb-3'} style={{fontSize: '10px', color: 'red'}}>이메일 중복검사를 해주세요</span> : null
                    }
                </div>
                <div className={'row my-2'}>
                    <div className="col-8">
                        <label className="form-label mb-0">비밀번호</label>
                        <input type="password"
                            className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                            name={'memPw'}
                            value={member.memPw}
                            onChange={updateMemInfo}
                        />
                    </div>
                    {pwInput && pwCheck ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>사용가능</span> : <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>영문+숫자 8글자 이상</span>
                    }
                </div>
                <div className={'row'}>
                    <div className="col-8 mb-2">
                        <label className="form-label mb-0">비밀번호 확인</label>
                        <input type="password"
                            className={"d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"}
                            name={'memPwChk'}
                            value={member.memPwChk}
                            onChange={updateMemInfo}
                        />
                    </div>
                    {pwDupleCheck && member.memPw && member.memPwChk ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>확인완료</span> : null}
                </div>
                <div className={'row'}>
                    <div className="col-8 my-2">
                        <label className="form-label mb-0">이름</label>
                        <input type="text"
                            className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                            name={'memName'}
                            onChange={updateMemInfo}
                            value={member.memName}
                        />
                    </div>
                    {!nameCheck && nameInput ? <span className={'mt-0 mb-3'} style={{fontSize: '10px'}}>한글 2~4글자만 입력 가능합니다.</span> : null }
                </div>
                <div className={'row'}>
                    <label className="form-label mb-0">연락처</label>
                    <div className="col-4 my-2">
                        <select className={'form-select ps-1'} style={{fontSize: '13px'}} id={'tell1'}
                                onChange={(event) => {
                                    makeTell(event);
                                }}
                        >
                            <option value="010">010</option>
                            <option value="010">011</option>
                            <option value="010">016</option>
                            <option value="010">017</option>
                            <option value="010">018</option>
                            <option value="010">019</option>
                        </select>
                    </div>
                    <div className="col-3 my-2 px-0 me-2">
                        <input type="text"
                               id={'tell2'}
                               className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                               onChange={(event) => {
                                   makeTell(event);
                               }}
                        />
                    </div>
                    <div className="col-3 my-2 px-0">
                        <input type="text"
                               id={'tell3'}
                               className="d-inline-flex focus-ring focus-ring-dark py-1 px-2 text-decoration-none border rounded-2 form-control"
                               onChange={(event) => {
                                   makeTell(event);
                               }}
                        />
                    </div>
                    {/*{!tellCheck && tellInput ? <span className={'mt-0 mb-3'} style={{fontSize: '11px'}}> - 제외 010포함 전체 입력</span> : null}*/}
                </div>

                <div className={'row my-3 d-flex justify-content-center'}>
                    <div className={'col-10 d-grid'}>
                        <button disabled={false} onClick={join} className={'btn btn-dark rounded-3'}>회원가입</button>
                    </div>
                </div>

                <div className={'row d-flex justify-content-center'}>
                    <div className={'col-10 d-grid'}>
                        <button className={'btn btn-dark rounded-3'} onClick={() => { navigate('/'); }}>로그인페이지</button>
                    </div>
                </div>


            </div>

      </div>

    );
}

export default Join;